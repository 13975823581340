import { Flex, Tabs, TabsProps, Typography } from 'antd';
import { selectCustomerByIdLoading } from 'app/features/customers/selectors';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { selectManagerById } from 'app/features/managers/selectors';
import { getManagerById, setManagerById } from 'app/features/managers/slice';
import { useTranslation } from 'react-i18next';

import Loading from 'components/Loading';
import { GeneralInfo, ManagerCustomers, PlanHistory } from './components';
import { INFO_TABS } from './_constant';
import { StyledManagerDetails } from './styled';

const { Title } = Typography;

const ManagerDetails: FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const managerById = useAppSelector(selectManagerById(id));
  const loadingById = useAppSelector(selectCustomerByIdLoading);
  const [activeKey, setActiveKey] = useState(INFO_TABS.general);
  const { t } = useTranslation();

  const onChangeTab = (key: string) => {
    setActiveKey(key);
  };

  const items: TabsProps['items'] = [
    {
      key: INFO_TABS.general,
      label: t('managers.general_info'),
      children: <GeneralInfo />
    },
    {
      key: INFO_TABS.plan,
      label: t('managers.plan_info'),
      children: <PlanHistory />
    },
    {
      key: INFO_TABS.cooperation,
      label: t('managers.attach_customers'),
      children: <ManagerCustomers />
    }
  ];

  useEffect(() => {
    if (id) {
      dispatch(getManagerById(Number(id)));
    }

    return () => {
      dispatch(setManagerById({}));
    };
  }, [dispatch, id]);

  if (loadingById) {
    return <Loading />;
  }

  return (
    <StyledManagerDetails>
      <Flex gap={24}>
        <ArrowLeftOutlined
          onClick={() => navigate('/managers')}
          style={{ fontSize: 24, color: 'black', cursor: 'pointer' }}
        />
        <Title level={2}>
          {managerById?.first_name} {managerById?.last_name}
        </Title>
      </Flex>
      <Tabs
        activeKey={activeKey}
        defaultActiveKey="general"
        items={items}
        onChange={onChangeTab}
      />
    </StyledManagerDetails>
  );
};

export default ManagerDetails;
