import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ErrorType, showErrorMessage } from 'helpers/errors';
import { AxiosError } from 'axios';
import { client } from 'api';
import { RootState } from 'app/store';

import { ManagersOrdersState } from './types';
import { selectManagerOrdersLimit, selectManagerOrdersPage } from './selectors';

const initialState: ManagersOrdersState = {
  orders: { results: [], total: 0 },
  orderProducts: { results: [], total: 0 },
  loadingOrderProducts: false,
  loadingManagerInfo: false,
  orderInfo: null,
  loading: false,
  page: 0,
  limit: 10
};

interface GetAllOrdersParams {
  name?: string | null;
  manager?: string | null;
  status?: string | null;
  payment?: string | null;
  date?: string | null;
}

interface GetOrderProductsParams {
  id: number;
  page?: number;
}

export const getAllManagersOrders = createAsyncThunk(
  'managersOrders/getAllManagersOrders',
  async (params: GetAllOrdersParams | undefined, { getState }) => {
    try {
      const state = getState() as RootState;
      const limit = selectManagerOrdersLimit()(state);
      const page = selectManagerOrdersPage()(state);
      const res = await client.get('manager-orders/admin', {
        params: {
          name: params?.name,
          status: params?.status,
          payment: params?.payment,
          manager: params?.manager,
          date: params?.date,
          page,
          limit
        }
      });

      return res.data.data;
    } catch (error) {
      showErrorMessage(error as AxiosError<ErrorType>);
      throw error;
    }
  }
);

export const getOrderProductsById = createAsyncThunk(
  'managersOrders/getOrdersProductsById',
  async (params: GetOrderProductsParams, { getState }) => {
    try {
      const state = getState() as RootState;
      const limit = selectManagerOrdersLimit()(state);
      const response = await client.get(
        `/manager-orders/admin/products/${params.id}`,
        {
          params: {
            page: params.page,
            limit
          }
        }
      );

      return response.data.data;
    } catch (error) {
      showErrorMessage(error as AxiosError<ErrorType>);
      throw error;
    }
  }
);

export const getManagerOrderInfoById = createAsyncThunk(
  'managersOrders/getManagerOrderInfoById',
  async (id: number, { dispatch }) => {
    try {
      const response = await client.get(`/manager-orders/admin/${id}`);

      return dispatch(setOrderInfo(response.data.data));
    } catch (error) {
      showErrorMessage(error as AxiosError<ErrorType>);
      throw error;
    }
  }
);

const managersOrdersSlice = createSlice({
  name: 'managersOrders',
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setOrderInfo: (state, action) => {
      state.orderInfo = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getAllManagersOrders.pending, state => {
        state.loading = true;
      })
      .addCase(getAllManagersOrders.fulfilled, (state, action) => {
        state.orders = action.payload;
        state.loading = false;
      })
      .addCase(getAllManagersOrders.rejected, state => {
        state.loading = false;
      })
      .addCase(getOrderProductsById.pending, state => {
        state.loadingOrderProducts = true;
      })
      .addCase(getOrderProductsById.fulfilled, (state, action) => {
        state.loadingOrderProducts = false;
        state.orderProducts = action.payload;
      })
      .addCase(getOrderProductsById.rejected, state => {
        state.loadingOrderProducts = false;
      })
      .addCase(getManagerOrderInfoById.pending, state => {
        state.loadingManagerInfo = true;
      })
      .addCase(getManagerOrderInfoById.fulfilled, state => {
        state.loadingManagerInfo = false;
      })
      .addCase(getManagerOrderInfoById.rejected, state => {
        state.loadingManagerInfo = false;
      });
  }
});

export const { setPage, setOrderInfo } = managersOrdersSlice.actions;
export default managersOrdersSlice.reducer;
