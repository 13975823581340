import { Empty, List } from 'antd';
import {
  selectManagerByIdLoading,
  selectManagerPlanHistory
} from 'app/features/managers/selectors';
import { getManagerPlanHistory } from 'app/features/managers/slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import PlanItem from './PlanItem';

const PAGE_LIMIT = 10;

const PlanHistory = () => {
  const { id } = useParams();
  const planHistory = useAppSelector(selectManagerPlanHistory);
  const loading = useAppSelector(selectManagerByIdLoading);
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(0);

  useEffect(() => {
    dispatch(
      getManagerPlanHistory({
        managerId: id,
        pagination: { limit: PAGE_LIMIT, page }
      })
    );
  }, [dispatch, id, page]);

  if (!planHistory?.results?.length) {
    return <Empty />;
  }

  return (
    <List
      loading={loading}
      itemLayout="vertical"
      size="large"
      dataSource={planHistory.results}
      pagination={
        planHistory.results.length > 0
          ? {
              align: 'center',
              showSizeChanger: false,
              current: page + 1,
              onChange: page => {
                setPage(page - 1);
              },
              total: planHistory.total,
              pageSize: PAGE_LIMIT
            }
          : false
      }
      renderItem={item => (
        <PlanItem
          start_date={item.start_date}
          manager_id={item.manager_id}
          plan={item.plan}
          total_price={item.total_price}
          end_date={item.end_date}
          key={`plan-item-${item.id}`}
        />
      )}
    />
  );
};

export default PlanHistory;
