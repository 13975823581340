import { App, Button, Form, Input, Modal, Space, Typography } from 'antd';
import { payDebt } from 'app/features/customers/slice';
import { PayDebtBodyType } from 'app/features/customers/types';
import { selectCustomerLoadingAction } from 'app/features/managers/selectors';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  onClose(): void;
  customerCode: string;
  customerId: number;
}

const { Title } = Typography;

const DebtOrder: FC<IProps> = ({
  onClose,
  customerCode: code,
  customerId: customer_id
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { message } = App.useApp();
  const loading = useAppSelector(selectCustomerLoadingAction);

  const onFinish = (value: PayDebtBodyType) => {
    dispatch(payDebt({ code, paid: value.paid, customer_id })).then(res => {
      if (res.meta.requestStatus === 'fulfilled') {
        message.success(t('customers.pay_debt_success_message'));
        onClose();
      }
    });
  };

  return (
    <Modal
      title={<Title level={4}>{t('customers.pay_off_debt')}</Title>}
      footer={null}
      onCancel={onClose}
      open
    >
      <Form onFinish={onFinish}>
        <Form.Item name="paid">
          <Space.Compact style={{ width: '100%' }}>
            <Input placeholder={t('product.enter_price')} />
            <Button
              htmlType="submit"
              disabled={loading}
              loading={loading}
              type="primary"
            >
              {t('common.confirm')}
            </Button>
          </Space.Compact>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default DebtOrder;
