import { Modal } from 'antd';
import styled from 'styled-components';

export const StyledListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(5, 5, 5, 0.06);
  padding-inline: 12px;
  border-radius: 8px;
  cursor: pointer;
  padding: 8px 12px;

  &:hover {
    background-color: #f5f5f5;
  }
`;

export const StyledModal = styled(Modal)`
  &&&&&& {
    height: 80vh;
    padding: 0;
    overflow-y: auto;
    border-radius: 8px;
  }
  .ant-modal-content {
    border-radius: 8px;
  }
`;
