import { DatePicker, Empty, Flex, Pagination, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import {
  selectOrderByCustomersData,
  selectCustomerByIdLoading
} from 'app/features/customers/selectors';
import { getOrdersByCustomerId } from 'app/features/customers/slice';
import { OrderProducts } from 'app/features/customers/types';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import Loading from 'components/Loading';
import { formatPrice } from 'utils/price';

const PAGE_LIMIT = 10;

const OrderByCustomer = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { id } = useParams();
  const orders = useAppSelector(selectOrderByCustomersData);
  const loading = useAppSelector(selectCustomerByIdLoading);
  const [date, setDate] = useState<string | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const changePage = (page: number) => {
    updateSearchParams('page', page.toString());
  };

  const updateSearchParams = (key: string, value: string) => {
    searchParams.set(key, value);
    setSearchParams(searchParams);
  };

  const onChangeDate = (date: string) => {
    updateSearchParams('page', '1');
    setDate(date ? dayjs(date).format('YYYY-MM-DD') : null);
  };

  const columns: ColumnsType<OrderProducts> = [
    {
      title: t('orders.product_code'),
      dataIndex: 'code',
      key: 'code'
    },
    {
      title: t('orders.product_barcode'),
      dataIndex: 'barcode',
      key: 'barcode'
    },
    {
      title: t('orders.product_count'),
      dataIndex: 'count',
      key: 'count'
    },
    {
      title: t('product.price'),
      dataIndex: 'price',
      key: 'price',
      render: formatPrice
    },
    {
      title: t('orders.total_price'),
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      render: (_, products) => formatPrice(products.count * products.price)
    }
  ];

  useEffect(() => {
    const page = Number(searchParams.get('page')) || 1;
    dispatch(
      getOrdersByCustomerId({
        id: Number(id),
        page: page - 1,
        date,
        limit: PAGE_LIMIT
      })
    );
  }, [dispatch, id, date, searchParams]);

  if (loading) {
    return <Loading />;
  }

  const currentPage = Number(searchParams.get('page')) || 1;

  return (
    <Flex gap={24} vertical>
      <Flex justify="flex-end">
        <DatePicker
          placeholder={t('common.select_date')}
          onChange={onChangeDate}
          defaultValue={date}
          style={{ width: '300px' }}
        />
      </Flex>

      {orders?.results?.length === 0 ? (
        <Empty />
      ) : (
        orders?.results?.map(item => (
          <Table
            key={item.id}
            columns={columns}
            dataSource={item.products || []}
            rowKey="id"
            title={() => (
              <Typography.Paragraph style={{ marginBottom: 0 }} strong>
                {dayjs(item.created_at).format('DD MMMM, YYYY')}
              </Typography.Paragraph>
            )}
            bordered
            pagination={false}
          />
        ))
      )}

      {!!orders.count && (
        <Flex justify="flex-end">
          <Pagination
            pageSize={PAGE_LIMIT}
            showSizeChanger={false}
            total={orders.count}
            current={currentPage}
            onChange={changePage}
          />
        </Flex>
      )}
    </Flex>
  );
};

export default OrderByCustomer;
