import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

const getAdminOrder = (state: RootState) => state.adminOrder;

export const selectAdminOrder = createSelector(
  [getAdminOrder],
  orders => orders.order
);

export const selectAdminOrderLoading = createSelector(
  [getAdminOrder],
  orders => orders.loading
);
