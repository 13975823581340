import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { client } from 'api';
import { AxiosError } from 'axios';
import { ErrorType, showErrorMessage } from 'helpers/errors';

import {
  GetAllLocations,
  LocationCreateParams,
  LocationUpdateParams,
  LocationsState
} from './types';

const initialState: LocationsState = {
  locations: [],
  total: 0,
  byId: {},
  loading: false,
  loadingById: false,
  loadingAction: false,
  selectedLocationId: null
};

export const getLocations = createAsyncThunk(
  'locations/getLocations',
  async (params: GetAllLocations) => {
    try {
      const res = await client.get('rows', {
        params: {
          page: params?.page,
          limit: 10,
          name: params?.search
        }
      });

      return res.data.data;
    } catch (error) {
      showErrorMessage(error as AxiosError<ErrorType>);
      throw error;
    }
  }
);

export const getLocationById = createAsyncThunk(
  'location/getLocationById',
  async (id: number) => {
    try {
      const response = await client.get(`/rows/${id}`);

      return response.data.data;
    } catch (error) {
      showErrorMessage(error as AxiosError<ErrorType>);
      throw error;
    }
  }
);

export const createLocation = createAsyncThunk(
  'locations/createLocations',
  async ({ parent_id, ...data }: LocationCreateParams) => {
    try {
      await client.post('/rows', {
        ...data,
        parent_id
      });
    } catch (error) {
      showErrorMessage(error as AxiosError<ErrorType>);
      throw error;
    }
  }
);

export const updateLocation = createAsyncThunk(
  'location/updateLocation',
  async ({ id, ...data }: LocationUpdateParams) => {
    try {
      await client.put(`/rows/${id}`, {
        ...data
      });
    } catch (error) {
      showErrorMessage(error as AxiosError<ErrorType>);
      throw error;
    }
  }
);

export const deleteLocations = createAsyncThunk(
  'locations/deleteLocations',
  async (id: number) => {
    try {
      await client.delete(`/rows/${id}`);
    } catch (error) {
      showErrorMessage(error as AxiosError<ErrorType>);
      throw error;
    }
  }
);

const locationsSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    clearLocationById: state => {
      state.byId = {};
    },
    setSelectedLocationId: (state, action: PayloadAction<number>) => {
      state.selectedLocationId = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getLocations.pending, state => {
        state.loading = true;
      })
      .addCase(getLocations.fulfilled, (state, action) => {
        state.locations = action.payload.results;
        state.total = action.payload.total;
        state.loading = false;
      })
      .addCase(getLocations.rejected, state => {
        state.loading = false;
      })
      .addCase(getLocationById.pending, state => {
        state.loadingById = true;
      })
      .addCase(getLocationById.fulfilled, (state, action) => {
        state.loadingById = false;
        state.byId[action.payload.id] = action.payload;
      })
      .addCase(getLocationById.rejected, state => {
        state.loadingById = false;
      })
      .addCase(deleteLocations.pending, state => {
        state.loadingAction = true;
      })
      .addCase(deleteLocations.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(deleteLocations.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(createLocation.pending, state => {
        state.loadingAction = true;
      })
      .addCase(createLocation.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(createLocation.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(updateLocation.pending, state => {
        state.loadingAction = true;
      })
      .addCase(updateLocation.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(updateLocation.rejected, state => {
        state.loadingAction = false;
      });
  }
});

export const { clearLocationById, setSelectedLocationId } =
  locationsSlice.actions;
export default locationsSlice.reducer;
