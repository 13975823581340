import {
  App,
  Button,
  Col,
  DatePicker,
  Form,
  InputNumber,
  Modal,
  Row
} from 'antd';
import { selectPlanActionLoading } from 'app/features/plans/selectors';
import {
  createPlan,
  getPlanById,
  getPlans,
  updatePlan
} from 'app/features/plans/slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { DATE_FORMAT } from 'constant/dates';
import dayjs, { Dayjs } from 'dayjs';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const { RangePicker } = DatePicker;
const PAGE_LIMIT = 10;

interface IProps {
  onClose(): void;
  id: string | null;
}

type FormType = {
  plan: number;
  date: Dayjs[];
};

const PlanModal: FC<IProps> = ({ onClose, id }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const { modal } = App.useApp();
  const loadingAction = useAppSelector(selectPlanActionLoading);

  const onFinish = (values: FormType) => {
    const startDate = values.date[0].format('DD-MM-YYYY');
    const endDate = values.date[1].format('DD-MM-YYYY');
    const body = {
      plan: values.plan,
      start_date: startDate,
      end_date: endDate
    };

    if (id) {
      dispatch(updatePlan({ ...body, id })).then(res => {
        if (res.meta.requestStatus === 'fulfilled') {
          onSuccess();
        }
      });

      return;
    }

    dispatch(createPlan(body)).then(res => {
      if (res.meta.requestStatus === 'fulfilled') {
        onSuccess();
      }
    });
  };

  const onSuccess = () => {
    onClose();
    dispatch(getPlans({ page: 0, limit: PAGE_LIMIT }));

    return modal.success({
      title: t(`plans.success_plan_${id ? 'update' : 'create'}`)
    });
  };

  useEffect(() => {
    if (id) {
      dispatch(getPlanById(id)).then(response => {
        if (response.meta.requestStatus === 'fulfilled') {
          const planData = response.payload;
          form.setFieldsValue({
            plan: planData?.plan,
            date: [
              dayjs(planData?.start_date, 'DD-MM-YYYY'),
              dayjs(planData?.end_date, 'DD-MM-YYYY')
            ]
          });
        }
      });
    }
  });

  return (
    <Modal
      title={t('managers.create_manager')}
      open
      onCancel={onClose}
      width={600}
      footer={[
        <Button key="cancel" onClick={onClose}>
          {t('common.cancel')}
        </Button>,
        <Button
          key="save"
          type="primary"
          onClick={form.submit}
          loading={loadingAction}
        >
          {t('common.save')}
        </Button>
      ]}
    >
      <Form form={form} onFinish={onFinish} layout="vertical" size="large">
        <Row gutter={[16, 0]}>
          <Col span={7}>
            <Form.Item
              rules={[{ required: true }]}
              label={t('plans.plan')}
              name="plan"
            >
              <InputNumber
                style={{ width: '100%' }}
                min={1}
                placeholder={t('plans.enter_plan')}
              />
            </Form.Item>
          </Col>
          <Col span={17}>
            <Form.Item
              rules={[{ required: true }]}
              label={t('plans.date')}
              name="date"
            >
              <RangePicker
                format={DATE_FORMAT}
                style={{ width: '100%' }}
                disabledDate={current => current < dayjs().endOf('day')}
                placeholder={[t('plans.start_date'), t('plans.end_date')]}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default PlanModal;
