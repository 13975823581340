import styled from 'styled-components';

export const StyledManagerDetails = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
  gap: 48px;

  &&& {
    .ant-tabs {
      gap: 42px;
    }
    .ant-tabs-nav-list {
      flex: 1;
    }
    .ant-tabs-tab {
      flex: 3;
      justify-content: center;
    }
  }
`;

export const StyledPlanItem = styled.div`
  display: flex;
  flex-direction: column;
  padding-block: 16px;
  width: 98%;
`;
