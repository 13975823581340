import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';

import authSlice from './features/auth/slice';
import locationsSlice from './features/locations/slice';
import productsSlice from './features/products/slice';
import customersSlice from './features/customers/slice';
import warehouseOrdersSlice from './features/warehouseOrders/slice';
import managersOrdersSlice from './features/managersOrders/slice';
import analyticsSlice from './features/analytics/slice';
import employeesSlice from './features/employees/slice';
import managersSlice from './features/managers/slice';
import locationModalSlice from './features/locationModal/slice';
import plansSlice from './features/plans/slice';
import adminOrderSlice from './features/adminOrders/slice';

export const store = configureStore({
  reducer: {
    auth: authSlice,
    locations: locationsSlice,
    products: productsSlice,
    customers: customersSlice,
    warehouseOrders: warehouseOrdersSlice,
    managersOrders: managersOrdersSlice,
    analytics: analyticsSlice,
    employees: employeesSlice,
    locationModal: locationModalSlice,
    managers: managersSlice,
    plans: plansSlice,
    adminOrder: adminOrderSlice
  }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
