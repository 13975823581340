import { FC, useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { getCustomerDebtAnalytic } from 'app/features/customers/slice';
import { selectCustomerDebtAnalytic } from 'app/features/customers/selectors';
import { Empty } from 'antd';

import { BasicChart } from 'components/charts';

interface IProps {
  code: string;
}

const DebtInfo: FC<IProps> = ({ code }) => {
  const dispatch = useAppDispatch();
  const analytic = useAppSelector(selectCustomerDebtAnalytic);

  useEffect(() => {
    dispatch(getCustomerDebtAnalytic(code));
  }, [dispatch, code]);

  const data = useMemo(() => {
    return analytic.map(item => ({
      date: new Date(item.payment_date).getTime(),
      value: item.total_pay
    }));
  }, [analytic]);

  if (!data.length) {
    return <Empty />;
  }

  return <BasicChart data={data} />;
};

export default DebtInfo;
