import {
  App,
  Button,
  Flex,
  Input,
  Spin,
  TreeSelect,
  Typography,
  Upload
} from 'antd';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  getAllProducts,
  setProductById,
  uploadProductExcel
} from 'app/features/products/slice';
import { getLocations } from 'app/features/locations/slice';
import {
  selectLocationsLoading,
  selectLocationsTotal
} from 'app/features/locations/selectors';
import { useSearchParams } from 'react-router-dom';
import { BASE_URL } from 'api';
import { ILocations } from 'app/features/locations/types';

import { SearchContainer } from 'pages/Products/styled';
import { _debounce } from 'utils/helpers';
import { transformLocationsToTreeData } from './_helper';
import ProductModal from '../ProductModal';

interface IPageHeader {
  title: string;
  onChangePage: (value: number) => void;
}

const { Title } = Typography;
const { Search } = Input;

const debounce = _debounce();

const ProductHeader: FC<IPageHeader> = ({ title, onChangePage }) => {
  const { t } = useTranslation();
  const { modal } = App.useApp();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const locationId = searchParams.get('filter') || '';
  const [openModal, setOpenModal] = useState(false);
  const [locationsData, setLocationsData] = useState<ILocations[]>([]);
  const [pageLocation, setPageLocation] = useState(0);
  const [searchLocation, setSearchLocation] = useState('');
  const loading = useAppSelector(selectLocationsLoading);
  const [loadingBottom, setLoadingBottom] = useState(false);
  const total = useAppSelector(selectLocationsTotal);

  const treeData = useMemo(
    () => transformLocationsToTreeData(locationsData || []),
    [locationsData]
  );

  const onSearch = e => {
    const searchValue = e.target.value;
    onChangePage(0);
    debounce(() => {
      dispatch(
        getAllProducts({
          search: searchValue,
          locations: locationId
        })
      );
    });

    if (searchValue) {
      searchParams.set('search', searchValue);
      setSearchParams(searchParams);
    } else {
      searchParams.delete('search');
      setSearchParams(searchParams);
    }
  };

  const onSearchLocation = (e: string) => {
    setPageLocation(0);
    setSearchLocation(e);
  };

  const onSelect = (newValue: string) => {
    if (newValue) {
      searchParams.set('filter', newValue);
      setSearchParams(searchParams);
    } else {
      searchParams.delete('filter');
      setSearchParams(searchParams);
    }
  };

  const onClear = () => {
    searchParams.delete('filter');
    setSearchParams(searchParams);
    setSearchLocation('');
    setPageLocation(0);
  };

  useEffect(() => {
    if (pageLocation > 0) {
      setLoadingBottom(true);
    }

    debounce(() => {
      dispatch(
        getLocations({
          search: searchLocation,
          page: pageLocation
        })
      )
        .then(res => {
          if (res.meta.requestStatus === 'fulfilled') {
            if (pageLocation > 0) {
              setLoadingBottom(false);
              setLocationsData(prevData => [
                ...prevData,
                ...res.payload.results
              ]);
            } else {
              setLocationsData([...res.payload.results]);
            }
          }
        })
        .finally(() => setLoadingBottom(false));
    });
  }, [dispatch, pageLocation, searchLocation]);

  const onUploadFile = ({ file }) => {
    dispatch(uploadProductExcel(file)).then(res => {
      if (res.meta.requestStatus === 'fulfilled') {
        modal.success({ title: t('common.excel_file_uploaded_successfully') });
      }
    });
  };

  const onClose = () => {
    setOpenModal(false);
    dispatch(setProductById({}));
  };

  const handleTreeSelectScroll: React.UIEventHandler<HTMLDivElement> = e => {
    const target = e.target as HTMLDivElement;

    const bottom =
      target.scrollHeight === target.scrollTop + target.clientHeight;

    if (bottom && !loading && total > locationsData?.length) {
      setPageLocation(prevPage => prevPage + 1);
    }
  };

  return (
    <>
      <Flex gap={20} justify="space-between" style={{ marginBottom: 20 }}>
        <Title level={2}>{title}</Title>
        <Flex style={{ maxWidth: 800 }} flex={1} vertical>
          <Flex gap={20}>
            <SearchContainer>
              <Search
                value={searchParams.get('search') || undefined}
                placeholder={t('common.search')}
                onChange={onSearch}
              />
            </SearchContainer>
            <TreeSelect
              style={{ width: '100%' }}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              treeData={treeData}
              loading={loading}
              placeholder={t('product.location')}
              onSelect={onSelect}
              onClear={onClear}
              allowClear
              value={searchParams.get('filter') || undefined}
              onSearch={onSearchLocation}
              showSearch
              treeDefaultExpandAll
              treeNodeFilterProp="title"
              onPopupScroll={handleTreeSelectScroll}
              dropdownRender={menu => (
                <>
                  <div>{menu}</div>
                  {loadingBottom && (
                    <Flex justify="center" align="center">
                      <Spin size="small" />
                    </Flex>
                  )}
                </>
              )}
            />
          </Flex>
          <Flex gap={20} justify="flex-end">
            <Button onClick={() => setOpenModal(true)}>
              {t('product.create_product')}
            </Button>
            <a
              download
              href={`${BASE_URL}/excel/export-products?locations=${locationId}`}
            >
              <Button disabled={!locationId}>
                {t('product.export_excel_by_location')}
              </Button>
            </a>
            <a download href={`${BASE_URL}/excel/products`}>
              <Button>{t('product.export_excel')}</Button>
            </a>
            <Upload
              name="file"
              accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .ods"
              onChange={onUploadFile}
              beforeUpload={() => false}
              showUploadList={false}
            >
              <Button type="primary">{t('product.upload_excel')}</Button>
            </Upload>
          </Flex>
        </Flex>
      </Flex>
      {openModal && <ProductModal onClose={onClose} isOpen={openModal} />}
    </>
  );
};

export default ProductHeader;
