import { Flex, Progress, Typography } from 'antd';
import { ProgressProps } from 'antd/lib';
import { PlanHistoryType } from 'app/features/managers/types';
import { DATE_FORMAT } from 'constant/dates';
import dayjs from 'dayjs';
import { FC } from 'react';

import { StyledPlanItem } from 'pages/ManagerDetails/styled';
import { CURRENCY } from 'utils/price';

const twoColors: ProgressProps['strokeColor'] = {
  '0%': '#108ee9',
  '100%': '#87d068'
};

const { Text } = Typography;

const PlanItem: FC<PlanHistoryType> = ({
  plan,
  total_price,
  start_date,
  end_date
}) => {
  const percent = Math.min((total_price / plan) * 100, 100);

  return (
    <StyledPlanItem>
      <Flex flex={1} vertical>
        <Flex justify="space-between" align="center" style={{ width: '96%' }}>
          <Text>{dayjs(start_date).format(DATE_FORMAT)}</Text>
          <Text
            style={{ fontWeight: 700 }}
          >{`${plan}${CURRENCY}/${total_price} ${CURRENCY}`}</Text>
          <Text>{dayjs(end_date).format(DATE_FORMAT)}</Text>
        </Flex>
        <Progress
          size={['', 12]}
          percent={Number(percent.toFixed(2))}
          strokeColor={twoColors}
        />
      </Flex>
    </StyledPlanItem>
  );
};

export default PlanItem;
