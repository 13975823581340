import styled, { css } from 'styled-components';

const getStyles = (type?: string) => {
  switch (type) {
    case 'overlay':
      return css`
        height: auto;
        padding: 24px;
        background-color: rgba(0, 0, 0, 0.7);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        text-align: center;
        z-index: 3;
        .loading-title {
          color: #ffffff;
        }
        .ant-progress-circle .ant-progress-text {
          color: #ffffff;
        }
      `;
    case 'static':
      return css`
        height: auto;
        text-align: center;
        padding: 0 24px 16px;
        .loading-title {
          font-size: 14px;
        }
      `;
  }
};

export const StyledLoading = styled.div<{ type?: string }>`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: var(--padding-md);
  ${({ type }) => getStyles(type)}
`;
