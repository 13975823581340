import { Checkbox, Flex, Card, Input, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import {
  selectCustomersData,
  selectCustomersDataTotal,
  selectCustomersLimit,
  selectCustomersLoading
} from 'app/features/customers/selectors';
import { getAllCustomers } from 'app/features/customers/slice';
import { ICustomers } from 'app/features/customers/types';
import { selectCustomerLoadingAction } from 'app/features/managers/selectors';
import {
  addCustomerFromManager,
  removeCustomerFromManager
} from 'app/features/managers/slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { _debounce } from 'utils/helpers';

const { Search } = Input;

const debounce = _debounce();
const { Text } = Typography;

const ManagerCustomers = () => {
  const customersData = useAppSelector(selectCustomersData);
  const total = useAppSelector(selectCustomersDataTotal);
  const loading = useAppSelector(selectCustomersLoading);
  const loadingAttachCustomer = useAppSelector(selectCustomerLoadingAction);
  const limit = useAppSelector(selectCustomersLimit);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { id } = useParams();

  const onChangePage = (page: number) => setPage(page);

  const onChangeAttach = (customer: ICustomers) => {
    if (customer.is_match) {
      dispatch(
        removeCustomerFromManager({
          manager_id: Number(id),
          customer_id: customer.id
        })
      ).then(res => {
        if (res.meta.requestStatus === 'fulfilled') {
          dispatch(
            getAllCustomers({
              search,
              managerId: id,
              page: page
            })
          );
        }
      });
    } else {
      dispatch(
        addCustomerFromManager({
          manager_id: Number(id),
          customer_id: customer.id
        })
      ).then(res => {
        if (res.meta.requestStatus === 'fulfilled') {
          dispatch(
            getAllCustomers({
              search,
              managerId: id,
              page: page
            })
          );
        }
      });
    }
  };

  const columns: ColumnsType<ICustomers> = [
    {
      title: t('customers.name'),
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: t('customers.code'),
      dataIndex: 'code',
      key: 'code'
    },
    {
      title: t('customers.email'),
      dataIndex: ['customers_info', 'email'],
      key: 'email',
      render: (text: string) => {
        return (
          <>
            <a href={`mailto: ${text}`}>{text}</a>
          </>
        );
      }
    },
    {
      title: t('customers.phone'),
      dataIndex: ['customers_info', 'phone'],
      key: 'phone'
    },
    {
      title: t('customers.address'),
      dataIndex: ['customers_info', 'address'],
      key: 'address'
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      render: (_, row) => {
        return (
          <Checkbox
            onChange={() => onChangeAttach(row)}
            disabled={loadingAttachCustomer}
            checked={row?.is_match}
          />
        );
      }
    }
  ];

  const onSearch = e => {
    const searchValue = e.target.value;

    onChangePage(0);

    setSearch(searchValue);
  };

  useEffect(() => {
    debounce(() => {
      dispatch(
        getAllCustomers({
          search,
          managerId: id,
          page: page
        })
      );
    });
  }, [dispatch, id, page, search]);

  return (
    <Card>
      <Flex gap={42} vertical>
        <Flex gap={24} justify="space-between">
          <Text strong style={{ fontSize: 20 }}>
            {t('managers.customers')}
          </Text>
          <Search
            style={{ width: 500 }}
            value={search}
            placeholder={t('common.search')}
            onChange={onSearch}
          />
        </Flex>
        <Table
          loading={loading}
          columns={columns}
          dataSource={customersData}
          rowKey="id"
          bordered
          pagination={{
            showSizeChanger: false,
            current: page + 1,
            onChange: page => {
              onChangePage(page - 1);
            },
            total,
            pageSize: limit
          }}
        />
      </Flex>
    </Card>
  );
};

export default ManagerCustomers;
