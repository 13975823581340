import { ExclamationCircleFilled } from '@ant-design/icons';
import { App, Button, Descriptions, Flex, Form, Modal } from 'antd';
import { DescriptionsProps } from 'antd/lib';
import { setLocationModalType } from 'app/features/locationModal/slice';
import { LocationModalTypes } from 'app/features/locationModal/types';
import { selectSelectedLocationId } from 'app/features/locations/selectors';
import { getLocations } from 'app/features/locations/slice';
import { ILocations } from 'app/features/locations/types';
import {
  selectLoadingAction,
  selectProductById
} from 'app/features/products/selectors';
import {
  getProductById,
  removeProductFromRow,
  setProductByCode,
  setProductById
} from 'app/features/products/slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

interface IProps {
  location?: ILocations;
}

const ProductModal: FC<IProps> = ({ location }) => {
  const { t } = useTranslation();
  const { modal } = App.useApp();
  const loadingAction = useAppSelector(selectLoadingAction);
  const [form] = Form.useForm();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const product = useAppSelector(selectProductById(id));
  const rowId = useAppSelector(selectSelectedLocationId);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const showDeleteConfirm = () => {
    modal.confirm({
      title: t('common.warning'),
      content: t('locations.delete_row_product_warning'),
      icon: <ExclamationCircleFilled />,
      okText: t('common.yes'),
      cancelText: t('common.no'),
      okType: 'danger',
      onOk() {
        onRemove();
      },
      okButtonProps: {
        loading: loadingAction
      }
    });
  };

  const onRemove = () => {
    dispatch(
      removeProductFromRow({ product_id: product?.id, row_id: location?.id })
    ).then(res => {
      if (res.meta.requestStatus === 'fulfilled') {
        dispatch(getLocations({ page: 0 }));
        dispatch(setLocationModalType(null));
        dispatch(setProductById({}));
        searchParams.delete('id');
        form.resetFields();
        modal.success({ title: t('product.product_was_removed') });
      }
    });
  };

  const onChangeModalType = (type: LocationModalTypes) => {
    dispatch(setLocationModalType(type));
  };

  const onModalClose = () => {
    navigate('/locations');
    dispatch(setLocationModalType(null));
    dispatch(setProductById({}));
    dispatch(setProductByCode(null));
    searchParams.delete('id');
  };

  useEffect(() => {
    if (id) {
      dispatch(getProductById({ id: Number(id), row_id: Number(rowId) }));
    }
  }, [dispatch, id, location?.id, rowId, searchParams]);

  const items: DescriptionsProps['items'] = [
    {
      label: t('product.code'),
      children: product?.code,
      key: 'code'
    },
    {
      label: t('product.barcode'),
      children: product?.barcode,
      key: 'barcode'
    },
    {
      label: t('product.unassigned'),
      children: product?.unassigned,
      key: 'unassigned'
    },
    {
      label: t('product.assigned_to_this_row'),
      children: product?.locations.find(item => item.id === rowId)?.count || 0,
      key: 'unassigned'
    },
    {
      label: t('product.total'),
      children: product?.total_amount,
      key: 'unassigned'
    }
  ];

  return (
    <Modal
      title={`${t('product.product_name')}: ${product?.name}`}
      footer={null}
      open
      onCancel={onModalClose}
    >
      {product && (
        <>
          <Descriptions
            style={{ marginTop: 24 }}
            layout="vertical"
            bordered
            column={1}
            size="small"
            items={items}
          />
          <Flex gap={8} justify="flex-end" style={{ marginTop: 24 }}>
            {product.is_match ? (
              <>
                <Button onClick={() => onChangeModalType('update')}>
                  {t('common.update')}
                </Button>
                <Button
                  type={product?.is_match ? 'default' : 'primary'}
                  danger={product?.is_match}
                  onClick={() => showDeleteConfirm()}
                >
                  {t('common.remove')}
                </Button>
              </>
            ) : (
              <Button
                onClick={() =>
                  onChangeModalType(product?.is_match ? 'update' : 'assign')
                }
                type="primary"
              >
                {t('locations.assign')}
              </Button>
            )}
          </Flex>
        </>
      )}
    </Modal>
  );
};

export default ProductModal;
