import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

const getManagers = (state: RootState) => state.managers;

export const selectManagersData = createSelector(
  [getManagers],
  manager => manager.managers
);

export const selectManagersDataTotal = createSelector(
  [getManagers],
  manager => manager.total
);

export const selectManagersLoading = createSelector(
  [getManagers],
  manager => manager.loading
);

export const selectManagersLimit = createSelector(
  [getManagers],
  manager => manager.limit
);

export const selectManagersLoadingAction = createSelector(
  [getManagers],
  manager => manager.loadingAction
);

export const selectCustomerLoadingAction = createSelector(
  [getManagers],
  manager => manager.loadingCustomerAction
);

export const selectManagerById = (id?: string) =>
  createSelector([getManagers], manager => (id ? manager.byId[id] : null));

export const selectManagerByIdLoading = createSelector(
  [getManagers],
  manager => manager.loadingById
);

export const selectManagerPlanHistory = createSelector(
  [getManagers],
  manager => manager.planHistory
);
