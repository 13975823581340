import { App, Button, Flex, Input, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  EyeOutlined,
  ExclamationCircleFilled
} from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  selectLocationsData,
  selectLocationsLoading,
  selectLocationsLoadingAction,
  selectLocationsTotal
} from 'app/features/locations/selectors';
import {
  deleteLocations,
  getLocationById,
  getLocations,
  setSelectedLocationId
} from 'app/features/locations/slice';
import { ILocations } from 'app/features/locations/types';
import { useNavigate } from 'react-router';
import { selectLocationModalType } from 'app/features/locationModal/selectors';
import { setLocationModalType } from 'app/features/locationModal/slice';
import { useSearchParams } from 'react-router-dom';
import { BASE_URL } from 'api';

import { PageWrapper } from 'components/ui';
import {
  LocationModal,
  ProductModal,
  SearchProductModal,
  UpdateModal
} from './components';
import { _debounce } from 'utils/helpers';

const { Search } = Input;
const debounce = _debounce();

const LIMIT = 10;

const Locations = () => {
  const { modal } = App.useApp();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loading = useAppSelector(selectLocationsLoading);
  const loadingAction = useAppSelector(selectLocationsLoadingAction);
  const locationsData = useAppSelector(selectLocationsData);
  const total = useAppSelector(selectLocationsTotal);
  const modalType = useAppSelector(selectLocationModalType);
  const [location, setLocation] = useState<ILocations>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(0);
  const [modalState, setModalState] = useState({
    isOpen: false,
    locationId: null as number | null
  });

  const productId = searchParams.get('id');

  useEffect(() => {
    const searchValue = searchParams.get('search');

    debounce(() => {
      dispatch(
        getLocations({
          search: searchValue,
          page: page
        })
      );
    });
  }, [dispatch, page, searchParams]);

  const onSearch = e => {
    const searchValue = e.target.value;

    onChangePage(0);

    if (searchValue) {
      searchParams.set('search', searchValue);
      setSearchParams(searchParams);
    } else {
      searchParams.delete('search');
      setSearchParams(searchParams);
    }
  };

  const onSaveAction = () => {
    const searchValue = searchParams.get('search');
    setPage(0);
    searchParams.delete('search');
    setSearchParams(searchParams);
    dispatch(getLocations({ page: 0, search: searchValue }));
  };

  const addLevels = (nodes, level = 0) => {
    return nodes?.map(node => {
      const { children, ...rest } = node;

      return {
        ...rest,
        level,
        ...(children &&
          children?.length && { children: addLevels(children, level + 1) })
      };
    });
  };

  const locationsWithLevels = addLevels(locationsData);
  const onChangePage = (page: number) => setPage(page);

  const showDeleteConfirm = (row: ILocations) => {
    modal.confirm({
      title: t('common.warning'),
      content: t('locations.delete_row_warning'),
      icon: <ExclamationCircleFilled />,
      okText: t('common.yes'),
      cancelText: t('common.no'),
      okType: 'danger',
      onOk() {
        removeLocation(row);
      },
      okButtonProps: {
        loading: loadingAction
      }
    });
  };

  const columns: ColumnsType<ILocations> = [
    {
      title: t('common.title'),
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '',
      dataIndex: 'view_products',
      key: 'view_products',
      width: 200,
      render: (_, row) => {
        return (
          row.is_assigned && (
            <Button
              onClick={() => navigate(`/products?filter=${row.id}`)}
              size="large"
            >
              {t('locations.view_products')}
              <EyeOutlined />
            </Button>
          )
        );
      }
    },
    {
      title: '',
      dataIndex: 'assign_product',
      key: 'assign_product',
      width: 200,
      render: (_, row) => {
        return (
          !row.children && (
            <Button
              onClick={() => {
                setLocation(row);
                dispatch(setLocationModalType('search-product'));
                dispatch(setSelectedLocationId(row.id));
              }}
              size="large"
            >
              {t('locations.assign_product')}
            </Button>
          )
        );
      }
    },
    {
      title: t('locations.action'),
      dataIndex: 'action',
      width: 100,
      key: 'Action',
      align: 'center',
      render: (_, row) => {
        return (
          <Flex justify="flex-end" gap={16}>
            {!row.children && (
              <Button
                danger
                onClick={() => showDeleteConfirm(row)}
                size="large"
                icon={<DeleteOutlined style={{ fontSize: 18 }} />}
              />
            )}
            {row.level !== 2 && (
              <Button
                type="primary"
                size="large"
                onClick={() => openLocationModal(row.id)}
                icon={<PlusOutlined style={{ fontSize: 18 }} />}
              />
            )}
            <Button
              size="large"
              onClick={async () => {
                await dispatch(getLocationById(row.id));
                openLocationModal(row.id);
              }}
              icon={<EditOutlined style={{ fontSize: 18 }} />}
            />
          </Flex>
        );
      }
    }
  ];

  const removeLocation = (row: ILocations) => {
    dispatch(deleteLocations(row.id)).then(res => {
      if (res.meta.requestStatus === 'fulfilled') {
        onSaveAction();
      }
    });
  };

  const closeLocationModal = () => {
    setModalState({ isOpen: false, locationId: null });
  };

  const openLocationModal = (id?: number | null) => {
    setModalState({ isOpen: true, locationId: id || null });
  };

  const renderModal = () => {
    switch (modalType) {
      case 'search-product':
        return <SearchProductModal location={location} />;

      case 'product':
        return <ProductModal location={location} />;

      case 'update':
        return (
          <UpdateModal
            onSaveAction={onSaveAction}
            id={productId}
            location={location}
          />
        );

      case 'assign':
        return (
          <UpdateModal
            onSaveAction={onSaveAction}
            id={productId}
            location={location}
          />
        );
    }
  };

  return (
    <PageWrapper
      title={t('common.locations')}
      extra={[
        <Flex
          gap={12}
          align="flex-end"
          justify="flex-start"
          vertical
          key="location-header"
        >
          <Search
            value={searchParams.get('search') || undefined}
            placeholder={t('common.search')}
            onChange={onSearch}
            style={{ minWidth: 500 }}
          />
          <Flex gap={24}>
            <Button key="create_location" onClick={() => openLocationModal()}>
              {t('locations.create_location')}
            </Button>
            <a download href={`${BASE_URL}/excel/all-data`}>
              <Button type="primary" style={{ marginBottom: 16 }}>
                {t('locations.export_excel')}
              </Button>
            </a>
          </Flex>
        </Flex>
      ]}
    >
      <Table
        loading={loading}
        columns={columns}
        dataSource={locationsWithLevels}
        rowKey="id"
        bordered
        pagination={{
          showSizeChanger: false,
          current: page + 1,
          onChange: page => {
            onChangePage(page - 1);
          },
          total,
          pageSize: LIMIT
        }}
      />
      <LocationModal
        isOpen={modalState.isOpen}
        locationId={modalState.locationId}
        onClose={closeLocationModal}
        onSaveAction={onSaveAction}
      />
      {renderModal()}
    </PageWrapper>
  );
};

export default Locations;
