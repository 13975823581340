import { App, Button, Form, Input, Modal } from 'antd';
import {
  selectCustomerById,
  selectCustomerByIdLoading,
  selectCustomersLoadingAction
} from 'app/features/customers/selectors';
import {
  createCustomer,
  getAllCustomers,
  getCustomerById,
  updateCustomer
} from 'app/features/customers/slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { FC, memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import Loading from 'components/Loading';

interface IProps {
  onClose: () => void;
  id: number | null;
}

export type CustomersValues = {
  name: string;
  code: string;
  email: string;
  phone: string;
  address: string;
};

const CustomersModal: FC<IProps> = ({ onClose, id }) => {
  // form
  const [form] = Form.useForm();
  // dispatch
  const dispatch = useAppDispatch();
  // selectors
  const loadingAction = useAppSelector(selectCustomersLoadingAction);
  const customerById = useAppSelector(selectCustomerById(id));
  const loadingById = useAppSelector(selectCustomerByIdLoading);
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const { modal } = App.useApp();

  const handleCloseModal = () => {
    form.resetFields();
    onClose();
  };

  const modalTitle = () => {
    if (id) {
      return t('customers.update_customers');
    }

    return t('customers.create_customers');
  };

  const onSave = async (values: CustomersValues) => {
    await form.validateFields();
    const searchValue = new URLSearchParams(window.location.search).get(
      'search'
    );

    const pageValue = Number(searchParams.get('page'));

    const page = pageValue ? pageValue - 1 : 0;

    if (id && customerById?.id) {
      return dispatch(
        updateCustomer({
          id: id,
          name: values.name,
          code: values.code,
          customers_info: {
            email: values.email,
            phone: values.phone,
            address: values.address
          }
        })
      ).then(res => {
        if (res.meta.requestStatus === 'fulfilled') {
          handleCloseModal();
          dispatch(
            getAllCustomers({
              search: searchValue,
              page: page
            })
          );
          modal.success({ title: t('customers.customer_was_update') });
        }
      });
    }

    return dispatch(
      createCustomer({
        name: values.name,
        code: values.code,
        customers_info: {
          email: values.email,
          phone: values.phone,
          address: values.address
        }
      })
    ).then(res => {
      if (res.meta.requestStatus === 'fulfilled') {
        dispatch(
          getAllCustomers({
            search: searchValue,
            page: page
          })
        );
        handleCloseModal();
        modal.success({ title: t('customers.customer_was_create') });
      }
    });
  };

  useEffect(() => {
    if (id && customerById?.id) {
      form.setFieldsValue({
        name: customerById?.name,
        code: customerById.code,
        email: customerById.customers_info.email,
        address: customerById.customers_info.address,
        phone: customerById.customers_info.phone
      });
    }
  }, [customerById, form, id]);

  useEffect(() => {
    if (id) {
      dispatch(getCustomerById(id));
    }
  }, [dispatch, id]);

  return (
    <Modal
      title={modalTitle()}
      open
      onCancel={handleCloseModal}
      footer={[
        <Button key="cancel" onClick={async () => handleCloseModal()}>
          {t('common.cancel')}
        </Button>,
        <Button
          key="save"
          type="primary"
          onClick={form.submit}
          loading={loadingAction}
        >
          {t('common.save')}
        </Button>
      ]}
    >
      {loadingById ? (
        <Loading />
      ) : (
        <Form form={form} onFinish={onSave} layout="vertical" size="large">
          <Form.Item
            name="name"
            label={t('customers.name')}
            rules={[{ required: true }]}
          >
            <Input placeholder={t('customers.name_placeholder')} />
          </Form.Item>
          <Form.Item name="code" label={t('customers.code')}>
            <Input placeholder={t('customers.code_placeholder')} />
          </Form.Item>
          <Form.Item
            name="email"
            label={t('customers.email')}
            rules={[
              {
                type: 'email',
                message: t('validation.input_is_not_valid_email')
              }
            ]}
          >
            <Input placeholder={t('customers.email_placeholder')} />
          </Form.Item>
          <Form.Item
            name="phone"
            label={t('customers.phone')}
            rules={[
              {
                // eslint-disable-next-line no-useless-escape
                pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
                message: t('validation.enter_phone_number')
              }
            ]}
          >
            <Input placeholder={t('customers.phone_placeholder')} />
          </Form.Item>
          <Form.Item name="address" label={t('customers.address')}>
            <Input placeholder={t('customers.address_placeholder')} />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default memo(CustomersModal);
