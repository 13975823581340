import { Button, Card, Divider, Empty, Flex, Tabs } from 'antd';
import {
  selectCustomerById,
  selectCustomerByIdLoading
} from 'app/features/customers/selectors';
import { getCustomerById } from 'app/features/customers/slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';
import { TabsProps } from 'antd/lib';

import OrderByCustomer from './components/OrderByCustomer';
import Loading from 'components/Loading';
import { PageWrapper } from 'components/ui';
import { DebtInfo, GeneralInfo } from './components';
import { INFO_TABS } from './_constants';

const CustomersDetails: FC = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const customer = useAppSelector(selectCustomerById(id));
  const loading = useAppSelector(selectCustomerByIdLoading);
  const [activeKey, setActiveKey] = useState(INFO_TABS.general);

  useEffect(() => {
    if (id) {
      dispatch(getCustomerById(Number(id)));
    }
  }, [dispatch, id]);

  const onChangeTab = (key: string) => {
    setActiveKey(key);
  };

  const items: TabsProps['items'] = [
    {
      key: INFO_TABS.general,
      label: t('customers.general_info'),
      children: id ? <GeneralInfo id={id} /> : <Empty />
    },
    {
      key: INFO_TABS.debt,
      label: t('customers.debt_info'),
      children: (
        <Card>
          {customer?.code ? <DebtInfo code={customer.code} /> : <Empty />}
        </Card>
      )
    }
  ];

  if (loading) {
    return <Loading />;
  }

  return (
    <PageWrapper
      back
      onClickBack={() => navigate('/customers')}
      title={customer?.name}
      extra={[
        <Button
          icon={<PlusOutlined />}
          key="create_order"
          onClick={() => navigate(`/create-order/${id}`)}
        >
          {t('customers.create_order')}
        </Button>
      ]}
    >
      <Flex vertical gap={16}>
        <Tabs
          activeKey={activeKey}
          defaultActiveKey="general"
          items={items}
          onChange={onChangeTab}
        />
        <Flex vertical>
          <Divider orientation="left">{t('customers.orders')}</Divider>
          <OrderByCustomer />
        </Flex>
      </Flex>
    </PageWrapper>
  );
};

export default CustomersDetails;
