import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

const getAnalytics = (state: RootState) => state.analytics;

export const selectAnalyticsLoading = createSelector(
  [getAnalytics],
  analytic => analytic.loading
);

export const selectCustomerOrderAnalytic = createSelector(
  [getAnalytics],
  analytic => analytic.customers_order
);

export const selectCustomerOrderCount = createSelector(
  [getAnalytics],
  analytic => analytic.customers_order_count
);

export const selectMonthlyOrderAnalytic = createSelector(
  [getAnalytics],
  analytic => analytic.monthly_order
);

export const selectMonthlyOrderCount = createSelector(
  [getAnalytics],
  analytic => analytic.monthly_order_count
);
