import styled from 'styled-components';
import { Button, Typography } from 'antd';

const { Title } = Typography;

export const PageContainer = styled.div`
  flex: 1 0 auto;
`;

export const Wrapper = styled.div<{ $color: string; $gap: number }>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${({ $gap }) => $gap}px;
  height: 100%;
  /* background-color: ${({ $color }) => ` var(--${$color})`}; */
  /* box-shadow: 0px 0px 15px 0px #e7eaf6; */
  /* padding: 16px; */
`;

export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledTitle = styled(Title)`
  &&& {
    align-items: center;
    margin: 0;
  }
`;

export const StyledButton = styled(Button)`
  color: #172541;
`;
