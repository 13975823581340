import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

const getEmployees = (state: RootState) => state.employees;

export const selectEmployeesData = createSelector(
  [getEmployees],
  employees => employees.employees
);

export const selectEmployeesTotal = createSelector(
  [getEmployees],
  employees => employees.total
);

export const selectEmployeesLoading = createSelector(
  [getEmployees],
  employees => employees.loading
);

export const selectEmployeesLimit = createSelector(
  [getEmployees],
  employees => employees.limit
);

export const selectEmployeesLoadingAction = createSelector(
  [getEmployees],
  employees => employees.loadingAction
);

export const selectEmployeesById = (id: string | undefined | null) =>
  createSelector([getEmployees], employees =>
    id ? employees.byId[id] : undefined
  );

export const selectEmployeesByIdLoading = createSelector(
  [getEmployees],
  employees => employees.loadingById
);
