import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { client } from 'api';
import { AxiosError } from 'axios';
import { ErrorType, showErrorMessage } from 'helpers/errors';
import { PaginationType } from 'types/pagination';

import { IPlan, PlanStateType } from './types';

const initialState: PlanStateType = {
  loading: false,
  actionLoading: false,
  planById: null,
  plans: {
    results: [],
    total: 0
  }
};

export const getPlans = createAsyncThunk(
  'plans/getPlans',
  async (pagination: PaginationType) => {
    try {
      const res = await client.get('managers-plans', {
        params: pagination
      });

      return res.data.data;
    } catch (error) {
      showErrorMessage(error as AxiosError<ErrorType>);
      throw error;
    }
  }
);

export const getPlanById = createAsyncThunk(
  'plans/getPlanById',
  async (id: string) => {
    try {
      const res = await client.get(`managers-plans/${id}`);

      return res.data.data;
    } catch (error) {
      showErrorMessage(error as AxiosError<ErrorType>);
      throw error;
    }
  }
);

export const createPlan = createAsyncThunk(
  'plans/createPlan',
  async (body: Omit<IPlan, 'id'>) => {
    try {
      await client.post('managers-plans', body);
    } catch (error) {
      showErrorMessage(error as AxiosError<ErrorType>);
      throw error;
    }
  }
);

export const updatePlan = createAsyncThunk(
  'plans/updatePlan',
  async ({ id, ...body }: IPlan) => {
    try {
      await client.put(`managers-plans/${id}`, { ...body });
    } catch (error) {
      showErrorMessage(error as AxiosError<ErrorType>);
      throw error;
    }
  }
);

export const deletePlan = createAsyncThunk(
  'plans/deletePlan',
  async (id: string) => {
    try {
      await client.delete(`managers-plans/${id}`);
    } catch (error) {
      showErrorMessage(error as AxiosError<ErrorType>);
      throw error;
    }
  }
);

const plansSlice = createSlice({
  name: 'plans',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getPlans.pending, state => {
        state.loading = true;
      })
      .addCase(getPlans.fulfilled, (state, action) => {
        state.loading = false;
        state.plans = action.payload;
      })
      .addCase(getPlans.rejected, state => {
        state.loading = false;
      })
      .addCase(createPlan.pending, state => {
        state.actionLoading = true;
      })
      .addCase(createPlan.fulfilled, state => {
        state.actionLoading = false;
      })
      .addCase(createPlan.rejected, state => {
        state.actionLoading = false;
      })
      .addCase(deletePlan.pending, state => {
        state.actionLoading = true;
      })
      .addCase(deletePlan.fulfilled, state => {
        state.actionLoading = false;
      })
      .addCase(deletePlan.rejected, state => {
        state.actionLoading = false;
      })
      .addCase(updatePlan.pending, state => {
        state.actionLoading = true;
      })
      .addCase(updatePlan.fulfilled, state => {
        state.actionLoading = false;
      })
      .addCase(updatePlan.rejected, state => {
        state.actionLoading = false;
      });
  }
});

export default plansSlice.reducer;
