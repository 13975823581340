import { useEffect, useState } from 'react';
import {
  App,
  AutoComplete,
  Button,
  Divider,
  Flex,
  List,
  Typography
} from 'antd';
import { selectAllProducts } from 'app/features/products/selectors';
import {
  getProductsForOrder,
  setProductById
} from 'app/features/products/slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  selectAdminOrder,
  selectAdminOrderLoading
} from 'app/features/adminOrders/selectors';
import {
  createOrder,
  removeProductFromOrder
} from 'app/features/adminOrders/slice';

import { _debounce } from 'utils/helpers';
import { PageWrapper } from 'components/ui';
import { ProductModal } from './components';
import { OrderItem } from './styled';
import { formatPrice } from 'utils/price';

const { Text } = Typography;

const debounce = _debounce();

const CreateOrder = () => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const products = useAppSelector(selectAllProducts);
  const orders = useAppSelector(selectAdminOrder);
  const loading = useAppSelector(selectAdminOrderLoading);
  const [productId, setProductId] = useState<number | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const search =
    new URLSearchParams(window.location.search).get('search') || '';

  useEffect(() => {
    debounce(() => {
      dispatch(
        getProductsForOrder({
          search,
          pagination: {
            page: 0,
            limit: 5
          }
        })
      );
    });
  }, [dispatch, search]);

  const onSearch = (e: string) => {
    const searchValue = e;

    if (searchValue) {
      searchParams.set('search', searchValue);
      setSearchParams(searchParams);
    } else {
      searchParams.delete('search');
      setSearchParams(searchParams);
    }
  };

  const onModalClose = () => {
    setProductId(null);
    dispatch(setProductById({}));
  };

  const onDeleteOrder = (id: number, row_id: number) => {
    dispatch(removeProductFromOrder({ id, row_id }));
  };

  const onSaveOrder = () => {
    if (id) {
      dispatch(
        createOrder(
          orders.map(order => ({
            count: order.count,
            customer_id: id,
            product_id: order.id,
            row_id: order.row_id
          }))
        )
      ).then(res => {
        if (res.meta.requestStatus === 'fulfilled') {
          message.success(t('orders.order_create_message'), 2);
          navigate('/orders/warehouse');
        }
      });
    }
  };

  return (
    <>
      <PageWrapper
        title={t('customers.create_order')}
        extra={[
          <Button
            loading={loading}
            onClick={onSaveOrder}
            disabled={!orders.length || loading}
            type="primary"
            key="create-order"
          >
            {t('orders.save_order')}
          </Button>
        ]}
      >
        <Flex justify="center">
          <AutoComplete
            style={{ width: '80%' }}
            placeholder={t('orders.find_product')}
            onSearch={onSearch}
            size="large"
            value={search || ''}
          >
            {products?.map(product => (
              <AutoComplete.Option key={product.id} value={product.name}>
                <List.Item
                  onClick={() => setProductId(product.id)}
                  key={product.id}
                >
                  <List.Item.Meta
                    title={product.name}
                    description={
                      <Flex vertical>
                        <Text style={{ paddingLeft: 10, color: '#999' }}>
                          {t('product.code')}: {product.code}
                        </Text>
                        <Text style={{ paddingLeft: 10, color: '#999' }}>
                          {t('product.barcode')}: {product.barcode}
                        </Text>
                      </Flex>
                    }
                  />
                </List.Item>
              </AutoComplete.Option>
            ))}
          </AutoComplete>
        </Flex>
        <Divider>{t('common.orders')}</Divider>
        <List
          dataSource={orders}
          grid={{ column: 1 }}
          renderItem={item => (
            <OrderItem key={item.id}>
              <Flex align="center">
                <List.Item.Meta
                  title={item.name}
                  description={
                    <Flex vertical style={{ paddingLeft: 8 }}>
                      <Text>
                        {t('product.count')}: {item.count}
                      </Text>
                      <Text>
                        {t('orders.total_price')}: {formatPrice(item.price)}
                      </Text>
                      <Text>
                        {t('locations.location_name')}: {item.row_name}
                      </Text>
                    </Flex>
                  }
                />
                <Flex gap={8}>
                  <Button onClick={() => setProductId(item.id)}>
                    {t('common.edit')}
                  </Button>
                  <Button
                    danger
                    onClick={() => onDeleteOrder(item.id, item.row_id)}
                  >
                    {t('common.delete')}
                  </Button>
                </Flex>
              </Flex>
            </OrderItem>
          )}
        />
      </PageWrapper>
      {!!productId && <ProductModal id={productId} onClose={onModalClose} />}
    </>
  );
};

export default CreateOrder;
