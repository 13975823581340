import { Card, Typography, Row, Col, Empty } from 'antd';
import {
  selectAnalyticsLoading,
  selectCustomerOrderAnalytic,
  selectCustomerOrderCount,
  selectMonthlyOrderCount
} from 'app/features/analytics/selectors';
import { getAllAnalytics } from 'app/features/analytics/slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Loading from 'components/Loading';
import CustomerTable from './CustomerTable';
import { PageWrapper } from 'components/ui';
import { OrderByDate, CustomersOrdersChart } from 'components/charts';

const { Title } = Typography;

const Dashboard = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectAnalyticsLoading);
  const customerOrderAnalytic = useAppSelector(selectCustomerOrderAnalytic);
  const monthlyOrderAnalytic = useAppSelector(selectCustomerOrderAnalytic);
  const customerOrderCount = useAppSelector(selectCustomerOrderCount);
  const monthlyOrderCount = useAppSelector(selectMonthlyOrderCount);

  const sorted = Object.entries(customerOrderAnalytic || {}).map(
    ([key, value]) => ({
      customer: key,
      orders: value as number
    })
  );

  useEffect(() => {
    dispatch(getAllAnalytics());
  }, [dispatch]);

  if (loading) {
    return <Loading />;
  }

  return (
    <PageWrapper title={t('common.dashboard')}>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          {!!monthlyOrderAnalytic && (
            <Card>
              <Title level={4} style={{ marginBottom: 20 }}>
                {t('dashboard.orders_by_dates')}
              </Title>
              <OrderByDate
                color="#4F84E8"
                data={Object.entries(monthlyOrderAnalytic || {})?.map(
                  ([key, value]) => ({
                    Date: `${key.charAt(0).toUpperCase()}${key.slice(1)}`,
                    Count: (value as number) || 0
                  })
                )}
              />
            </Card>
          )}
        </Col>
        <Col span={18}>
          <Card>
            <Title style={{ marginBottom: 20 }} level={4}>
              {t('dashboard.customers_order')}
            </Title>
            {customerOrderAnalytic ? (
              <CustomersOrdersChart
                dataOrders={Object.entries(customerOrderAnalytic || {})?.map(
                  ([key, value]) => {
                    return {
                      customer: `${key.charAt(0).toUpperCase()}${key.slice(1)}`,
                      orders: (value as number) || 0
                    };
                  }
                )}
              />
            ) : (
              <Empty />
            )}
          </Card>
        </Col>
        <CustomerTable
          data={sorted}
          customerCount={customerOrderCount}
          orderCount={monthlyOrderCount}
        />
      </Row>
    </PageWrapper>
  );
};

export default Dashboard;
