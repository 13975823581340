import styled from 'styled-components';

export const ChartContainer = styled.div`
  position: relative;
  height: 90%;
  width: 100%;
  .chartdiv {
    width: 100%;
    height: 100%;
  }
  .remove_logo {
    position: absolute;
    width: 10%;
    height: 25px;
    background: #fff;
    bottom: 0;
  }
`;
