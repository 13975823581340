import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Flex, Input, Typography } from 'antd';
import { useSearchParams } from 'react-router-dom';

import { SearchContainer, StyledHeader } from './styled';

const { Title } = Typography;
const { Search } = Input;

interface IProps {
  onOpenModal: () => void;
  onChangePage: (page: number) => void;
}

const EmployeesHeader: FC<IProps> = ({ onOpenModal, onChangePage }) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const onSearch = e => {
    const searchValue = e.target.value;

    onChangePage(0);

    if (searchValue) {
      searchParams.set('search', searchValue);
      setSearchParams(searchParams);
    } else {
      searchParams.delete('search');
      setSearchParams(searchParams);
    }
  };

  return (
    <StyledHeader>
      <Flex justify="space-between">
        <Title style={{ whiteSpace: 'nowrap' }} level={2}>
          {t('common.employees')}
        </Title>
        <SearchContainer>
          <Search
            value={searchParams.get('search') || undefined}
            placeholder={t('common.search')}
            onChange={onSearch}
          />
        </SearchContainer>
      </Flex>
      <Flex gap={20} justify="flex-end">
        <Button onClick={onOpenModal}>{t('employees.create_employees')}</Button>
      </Flex>
    </StyledHeader>
  );
};

export default EmployeesHeader;
