import { App, Button, Form, Input, Modal } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { FC, memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import {
  selectEmployeesById,
  selectEmployeesByIdLoading,
  selectEmployeesLoadingAction
} from 'app/features/employees/selectors';
import {
  createEmployees,
  getAllEmployees,
  getEmployeesById,
  setEmployeesById,
  updateEmployees
} from 'app/features/employees/slice';

import Loading from 'components/Loading';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  id: string | null;
}

export type EmployeeValues = {
  name: string;
  surname: string;
};

const EmployeesModal: FC<IProps> = ({ isOpen, onClose, id }) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const loadingAction = useAppSelector(selectEmployeesLoadingAction);
  const employeeById = useAppSelector(selectEmployeesById(id));
  const loadingById = useAppSelector(selectEmployeesByIdLoading);
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const { modal } = App.useApp();

  const handleCloseModal = () => {
    form.resetFields();
    dispatch(setEmployeesById({}));
    onClose();
  };

  const modalTitle = () => {
    if (id) {
      return t('employees.update_employee');
    }

    return t('employees.create_employee');
  };

  const onSave = async (values: EmployeeValues) => {
    await form.validateFields();
    const searchValue = new URLSearchParams(window.location.search).get(
      'search'
    );

    const pageValue = Number(searchParams.get('page'));

    const page = pageValue ? pageValue - 1 : 0;

    if (id && employeeById?.id) {
      return dispatch(
        updateEmployees({
          id,
          name: values.name,
          surname: values.surname
        })
      ).then(res => {
        if (res.meta.requestStatus === 'fulfilled') {
          handleCloseModal();
          dispatch(
            getAllEmployees({
              search: searchValue,
              page: page
            })
          );
          modal.success({ title: t('employees.employees_was_update') });
        }
      });
    }

    return dispatch(
      createEmployees({
        name: values.name,
        surname: values.surname
      })
    ).then(res => {
      if (res.meta.requestStatus === 'fulfilled') {
        handleCloseModal();
        dispatch(
          getAllEmployees({
            search: searchValue,
            page: page
          })
        );
        modal.success({ title: t('employees.employees_was_create') });
      }
    });
  };

  useEffect(() => {
    if (id && employeeById?.id) {
      form.setFieldsValue({
        name: employeeById?.name,
        surname: employeeById?.surname
      });
    }
  }, [employeeById, form, id]);

  useEffect(() => {
    if (id) {
      dispatch(getEmployeesById(id));
    }
  }, [dispatch, id]);

  return (
    <Modal
      title={modalTitle()}
      open={isOpen}
      onCancel={handleCloseModal}
      footer={[
        <Button key="cancel" onClick={async () => handleCloseModal()}>
          {t('common.cancel')}
        </Button>,
        <Button
          key="save"
          type="primary"
          onClick={form.submit}
          loading={loadingAction}
        >
          {t('common.save')}
        </Button>
      ]}
    >
      {loadingById ? (
        <Loading />
      ) : (
        <Form form={form} onFinish={onSave} layout="vertical" size="large">
          <Form.Item
            name="name"
            label={t('employees.name')}
            rules={[{ required: true }]}
            style={{ marginBottom: 6 }}
          >
            <Input placeholder={t('employees.name_placeholder')} />
          </Form.Item>
          <Form.Item
            rules={[{ required: true }]}
            name="surname"
            label={t('employees.surname')}
          >
            <Input placeholder={t('employees.surname_placeholder')} />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default memo(EmployeesModal);
