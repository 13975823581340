import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Flex, Typography } from 'antd';

import { StyledHeader } from './styled';

const { Title } = Typography;

interface IProps {
  openModal: () => void;
  onChangePage: (page: number) => void;
}

const ManagersHeader: FC<IProps> = ({ openModal }) => {
  const { t } = useTranslation();

  return (
    <StyledHeader>
      <Flex justify="space-between">
        <Title style={{ whiteSpace: 'nowrap' }} level={2}>
          {t('common.plans')}
        </Title>
        <Button size="large" onClick={openModal}>
          {t('plans.create_plan')}
        </Button>
      </Flex>
    </StyledHeader>
  );
};

export default ManagersHeader;
