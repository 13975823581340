import {
  Button,
  Col,
  Flex,
  Form,
  Input,
  InputNumber,
  List,
  Row,
  Steps,
  Typography,
  Upload,
  UploadFile
} from 'antd';
import {
  selectLoadingAction,
  selectProductById
} from 'app/features/products/selectors';
import {
  createProduct,
  getProductById,
  updateProduct
} from 'app/features/products/slice';
import { LocationsType } from 'app/features/products/types';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { UploadOutlined } from '@ant-design/icons';
import { BASE_URL } from 'api';

import { LocationStepStyled, StyledModal } from './styled';
import { useUpload } from 'hooks/useUpload';
import { CURRENCY } from 'utils/price';

const { Paragraph } = Typography;
const { Dragger } = Upload;

interface IProps {
  id?: number;
  isOpen: boolean;
  onClose: () => void;
}

type BodyType = {
  barcode: string;
  code: string;
  total_amount: number;
  unassigned: number;
  assigned: number;
  price: number;
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  files: any;
};

const ProductModal: FC<IProps> = ({ isOpen, onClose, id }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const product = useAppSelector(selectProductById(id));
  const loadingAction = useAppSelector(selectLoadingAction);
  const { onUploadFile, clearUploadQueue } = useUpload();

  useEffect(() => {
    if (isOpen && id) {
      dispatch(getProductById({ id }));
    }
  }, [dispatch, id, isOpen]);

  const onFinish = useCallback(
    async (values: BodyType) => {
      let files;

      if (values?.files?.length) {
        const existingFiles = values.files
          ?.filter(item => !!item.url && item.status === 'done')
          .map(item => item.uid);

        const newFiles = values.files
          ?.filter(item => !!item.response)
          .map(item => item?.response?.data?.data.id);

        files = Array.from(new Set([...existingFiles, ...newFiles]));
      }

      const updateInput = {
        barcode: values.barcode,
        code: values.code,
        unassigned: values.unassigned,
        name: values.name,
        price: values.price,
        files: files || []
      };

      const createInput = {
        barcode: values.barcode,
        code: values.code,
        total_amount: values.total_amount,
        name: values.name,
        price: values.price,
        files: files || []
      };

      if (id) {
        await dispatch(updateProduct({ id, ...updateInput }));
      } else {
        await dispatch(createProduct(createInput));
      }

      searchParams.delete('filter');
      searchParams.delete('search');
      setSearchParams(searchParams);
      onClose();
      form.resetFields();
    },
    [dispatch, form, id, onClose, searchParams, setSearchParams]
  );

  const getLocationNames = useCallback(
    (location: LocationsType, data: LocationsType[] = []) => {
      const result = [location, ...data];

      if (location.parent) {
        return getLocationNames(location.parent, result);
      }

      return result;
    },
    []
  );

  useEffect(() => {
    if (id && product && isOpen) {
      const files: UploadFile[] =
        product.files?.map(file => ({
          uid: file.id,
          name: file.name,
          status: 'done',
          url: `${BASE_URL}/assets/uploads/${file.name}`
        })) || [];

      form.setFieldsValue({
        name: product.name,
        code: product.code,
        barcode: product.barcode,
        total_amount: product.total_amount,
        assigned: product.count,
        unassigned: product.unassigned,
        price: product.price,
        files
      });
    }
  }, [form, product, id, getLocationNames, isOpen]);

  const onCloseModal = () => {
    onClose();
    form.resetFields();
    clearUploadQueue();
  };

  return (
    <div>
      <StyledModal
        width={800}
        footer={null}
        open={isOpen}
        onCancel={onCloseModal}
      >
        <a download href={`${BASE_URL}/excel/export-productId/${id}`}>
          <Button type="primary" style={{ marginBottom: 16 }}>
            {t('product.export_excel')}
          </Button>
        </a>
        <Flex vertical>
          <Form layout="vertical" form={form} onFinish={onFinish}>
            <Row>
              <Col style={{ marginBottom: 16 }} span={24}>
                <Form.Item name="name" label={t('product.name')}>
                  <Input placeholder={t('product.enter_name')} />
                </Form.Item>
              </Col>
              <Col style={{ marginBottom: 16 }} span={24}>
                <Form.Item
                  rules={[{ required: true }]}
                  name="code"
                  label={t('product.code')}
                >
                  <Input placeholder={t('product.enter_code')} />
                </Form.Item>
              </Col>
              <Col style={{ marginBottom: 16 }} span={24}>
                <Form.Item name="barcode" label={t('product.barcode')}>
                  <Input placeholder={t('product.enter_barcode')} />
                </Form.Item>
              </Col>
              <Col style={{ marginBottom: 16 }} span={24}>
                <Form.Item
                  name="price"
                  rules={[{ required: true }]}
                  label={t('product.price')}
                >
                  <InputNumber
                    addonAfter={CURRENCY}
                    style={{ width: '100%' }}
                    placeholder={t('product.enter_price')}
                    onKeyPress={e => {
                      if (!/^\d$/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col style={{ marginBottom: 16 }} span={24}>
                <Form.Item
                  label={t('product.product_images')}
                  name="files"
                  valuePropName="fileList"
                  getValueFromEvent={e => e.fileList || []}
                >
                  <Dragger
                    accept="image/*"
                    customRequest={onUploadFile}
                    listType="picture"
                    multiple
                  >
                    <Button icon={<UploadOutlined />}>
                      {t('product.upload_images')}
                    </Button>
                  </Dragger>
                </Form.Item>
              </Col>
              {!id && (
                <Col style={{ marginBottom: 16 }} span={24}>
                  <Form.Item
                    rules={[
                      {
                        // eslint-disable-next-line no-useless-escape
                        pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
                        message: t('validation.only_numbers')
                      },
                      {
                        required: true
                      }
                    ]}
                    name="total_amount"
                    label={t('product.total')}
                  >
                    <Input placeholder={t('product.enter_total')} />
                  </Form.Item>
                </Col>
              )}
              {id && (
                <>
                  <Flex justify="space-between">
                    <Col style={{ marginBottom: 16 }} span={11}>
                      <Form.Item name="total_amount" label={t('product.total')}>
                        <Input disabled />
                      </Form.Item>
                    </Col>
                    <Col style={{ marginBottom: 16 }} span={11}>
                      <Form.Item name="assigned" label={t('product.assigned')}>
                        <Input disabled />
                      </Form.Item>
                    </Col>
                  </Flex>
                  <Col style={{ marginBottom: 16 }} span={24}>
                    <Form.Item
                      name="unassigned"
                      label={t('product.unassigned')}
                      rules={[
                        {
                          pattern:
                            // eslint-disable-next-line no-useless-escape
                            /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
                          message: t('validation.only_numbers')
                        }
                      ]}
                    >
                      <Input
                        placeholder={t('product.change_product_quantity')}
                      />
                    </Form.Item>
                  </Col>
                </>
              )}
            </Row>
          </Form>
          {!!product?.locations?.length && (
            <>
              <Paragraph style={{ marginBottom: 8 }}>
                {t('common.locations')}
              </Paragraph>
              <List
                itemLayout="horizontal"
                dataSource={product.locations}
                renderItem={item => {
                  const result = getLocationNames(item);
                  const lastIndex = result?.length - 1;

                  return (
                    <List.Item>
                      <LocationStepStyled gap={12} vertical>
                        <List.Item.Meta
                          title={`${t('product.count')}: ${item.count}`}
                        />
                        <Steps
                          key={item.id}
                          progressDot={false}
                          current={lastIndex}
                          type="navigation"
                          size="small"
                          items={result.map(item => ({
                            title: item.name
                          }))}
                        />
                      </LocationStepStyled>
                    </List.Item>
                  );
                }}
              />
            </>
          )}
          <Flex justify="center">
            <Button
              type="primary"
              style={{ minWidth: 168 }}
              shape="round"
              loading={loadingAction}
              onClick={form.submit}
              size="large"
            >
              {t(`common.${id ? 'update' : 'create'}`)}
            </Button>
          </Flex>
        </Flex>
      </StyledModal>
    </div>
  );
};

export default ProductModal;
