import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { client } from 'api';
import { AxiosError } from 'axios';
import { ErrorType, showErrorMessage } from 'helpers/errors';
import { PaginationType } from 'types/pagination';

import { selectManagersLimit } from './selectors';
import { IActionCustomer, ManagersState, ManagerActionType } from './types';

const initialState: ManagersState = {
  managers: [],
  total: 0,
  byId: {},
  loading: false,
  loadingCustomerAction: false,
  loadingById: false,
  loadingAction: false,
  limit: 10,
  planHistory: {
    results: [],
    total: 0
  }
};

interface GetAllManagersParams {
  search?: string | null;
  page?: number | null;
  limit?: number;
}

export const getAllManagers = createAsyncThunk(
  'managers/getAllManagers',
  async (params: GetAllManagersParams | undefined, { getState }) => {
    try {
      const state = getState() as RootState;
      const limit = selectManagersLimit(state);
      const res = await client.get('managers', {
        params: {
          page: params?.page,
          limit: params?.limit || limit,
          name: params?.search
        }
      });

      return res.data.data;
    } catch (error) {
      showErrorMessage(error as AxiosError<ErrorType>);
      throw error;
    }
  }
);

export const createManager = createAsyncThunk(
  'managers/createManager',
  async (body: Omit<ManagerActionType, 'id'>) => {
    try {
      await client.post('managers', body);
    } catch (error) {
      showErrorMessage(error as AxiosError<ErrorType>);
      throw error;
    }
  }
);

export const updateManager = createAsyncThunk(
  'managers/updateManager',
  async ({ id, ...data }: Omit<ManagerActionType, 'password'>) => {
    try {
      await client.put(`managers/${id}`, {
        ...data
      });
    } catch (error) {
      showErrorMessage(error as AxiosError<ErrorType>);
      throw error;
    }
  }
);

export const updateManagerPassword = createAsyncThunk(
  'managers/updateManagerPassword',
  async ({ id, password }: { id: number; password: string }) => {
    try {
      await client.put(`managers/update-pass/${id}`, {
        password
      });
    } catch (error) {
      showErrorMessage(error as AxiosError<ErrorType>);
      throw error;
    }
  }
);

export const getManagerById = createAsyncThunk(
  'managers/getManagerById',
  async (id: number) => {
    try {
      const response = await client.get(`/managers/${id}`);

      return response.data.data;
    } catch (error) {
      showErrorMessage(error as AxiosError<ErrorType>);
      throw error;
    }
  }
);

export const removeCustomerFromManager = createAsyncThunk(
  'managers/removeCustomerFromManager',
  async (body: IActionCustomer) => {
    try {
      await client.post('managers/delete-attach-customer', body);
    } catch (error) {
      showErrorMessage(error as AxiosError<ErrorType>);
      throw error;
    }
  }
);

export const addCustomerFromManager = createAsyncThunk(
  'managers/addCustomerFromManager',
  async (body: IActionCustomer) => {
    try {
      await client.post('managers/attach-customer', body);
    } catch (error) {
      showErrorMessage(error as AxiosError<ErrorType>);
      throw error;
    }
  }
);

export const getManagerPlanHistory = createAsyncThunk(
  'managers/getManagerPlanHistory',
  async ({
    managerId,
    pagination
  }: {
    managerId: string | undefined;
    pagination: PaginationType;
  }) => {
    try {
      const res = await client.get(`plans-history/${managerId}`, {
        params: pagination
      });

      return res.data.data;
    } catch (error) {
      showErrorMessage(error as AxiosError<ErrorType>);
      throw error;
    }
  }
);

const managersSlice = createSlice({
  name: 'managers',
  initialState,
  reducers: {
    setManagerById: (state, action) => {
      state.byId = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getAllManagers.pending, state => {
        state.loading = true;
      })
      .addCase(getAllManagers.fulfilled, (state, action) => {
        state.managers = action.payload.results;
        state.total = action.payload.total;
        state.loading = false;
      })
      .addCase(getAllManagers.rejected, state => {
        state.loading = false;
      })
      .addCase(createManager.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(createManager.pending, state => {
        state.loadingAction = true;
      })
      .addCase(createManager.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(getManagerById.pending, state => {
        state.loadingById = true;
      })
      .addCase(getManagerById.fulfilled, (state, action) => {
        state.loadingById = false;
        state.byId[action.payload.id] = action.payload;
      })
      .addCase(getManagerById.rejected, state => {
        state.loadingById = false;
      })
      .addCase(updateManager.pending, state => {
        state.loadingAction = true;
      })
      .addCase(updateManager.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(updateManager.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(updateManagerPassword.pending, state => {
        state.loadingAction = true;
      })
      .addCase(updateManagerPassword.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(updateManagerPassword.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(removeCustomerFromManager.pending, state => {
        state.loadingCustomerAction = true;
      })
      .addCase(removeCustomerFromManager.fulfilled, state => {
        state.loadingCustomerAction = false;
      })
      .addCase(removeCustomerFromManager.rejected, state => {
        state.loadingCustomerAction = false;
      })
      .addCase(addCustomerFromManager.pending, state => {
        state.loadingCustomerAction = true;
      })
      .addCase(addCustomerFromManager.fulfilled, state => {
        state.loadingCustomerAction = false;
      })
      .addCase(addCustomerFromManager.rejected, state => {
        state.loadingCustomerAction = false;
      })
      .addCase(getManagerPlanHistory.pending, state => {
        state.loadingById = true;
      })
      .addCase(getManagerPlanHistory.fulfilled, (state, action) => {
        state.loadingById = false;
        state.planHistory = action.payload;
      })
      .addCase(getManagerPlanHistory.rejected, state => {
        state.loadingById = false;
      });
  }
});

export const { setManagerById } = managersSlice.actions;
export default managersSlice.reducer;
