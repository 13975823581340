import { List } from 'antd';
import styled from 'styled-components';

export const OrderItem = styled(List.Item)`
  &&& {
    background-color: var(--white);
    border: 1px solid #ccc;
    padding: 8px 12px;
    border-radius: 12px;
  }
`;
