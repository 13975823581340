import { AxiosError } from 'axios';
import { message } from 'antd';

export type ErrorType = {
  data: null;
  message: string;
  status: number;
};

export const showErrorMessage = (err: AxiosError<ErrorType>) => {
  message.error(err?.response?.data?.message || 'Something went wrong');
};
