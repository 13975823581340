import { FC } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Progress, Typography } from 'antd';

import { StyledLoading } from './styled';

const { Title } = Typography;

interface IProps {
  type?: 'screen' | 'static' | 'overlay';
  percent?: number;
}

const Loading: FC<IProps> = ({ type, percent }) => {
  return (
    <StyledLoading type={type}>
      {percent ? (
        <>
          <Progress
            percent={percent}
            type="circle"
            strokeColor={{ '0%': '#108ee9', '100%': '#87d068' }}
            style={{ color: '#ffffff' }}
          />
          <Title level={5} className="loading-title">
            Please keep this window open. Your upload is in progress and closing
            this window or browser may interrupt the process. We appreciate your
            patience!
          </Title>
        </>
      ) : (
        <>
          <LoadingOutlined style={{ fontSize: 50 }} />
          <Title level={2}>Loading...</Title>
        </>
      )}
    </StyledLoading>
  );
};

export default Loading;
