import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

const getProducts = (state: RootState) => state.products;

export const selectAllProducts = createSelector(
  [getProducts],
  products => products.products
);

export const selectProductsTotal = createSelector(
  [getProducts],
  products => products.total
);

export const selectAllProductsLoading = createSelector(
  [getProducts],
  products => products.loading
);

export const selectProductsLimit = createSelector(
  [getProducts],
  products => products.limit
);

export const selectProductById = (id?: number | null | string) =>
  createSelector([getProducts], products => {
    return id ? products.byId[id] : null;
  });

export const selectProductsByCode = createSelector(
  [getProducts],
  products => products.productsByCode
);

export const selectLoadingAction = createSelector(
  [getProducts],
  products => products.loadingAction
);
