import React, { FC, useEffect, useState } from 'react';
import {
  App,
  Button,
  Descriptions,
  Divider,
  Flex,
  Input,
  List,
  Modal,
  Typography
} from 'antd';
import { DescriptionsProps } from 'antd/lib';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { selectAdminOrder } from 'app/features/adminOrders/selectors';
import { addProductToOrder } from 'app/features/adminOrders/slice';
import { selectProductById } from 'app/features/products/selectors';
import { getProductById } from 'app/features/products/slice';

import { formatPrice } from 'utils/price';

interface IProps {
  id: number;
  onClose(): void;
}

const { Text } = Typography;

const ProductModal: FC<IProps> = ({ id, onClose }) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const dispatch = useAppDispatch();
  const product = useAppSelector(selectProductById(id));
  const orders = useAppSelector(selectAdminOrder);
  const [counts, setCounts] = useState<{ [key: number]: number }>({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    if (id) {
      dispatch(getProductById({ id: Number(id) }));
    }
  }, [dispatch, id]);

  useEffect(() => {
    const counts = orders.reduce((acc, order) => {
      acc[order.row_id] = order.count;

      return acc;
    }, {});

    setCounts(prevCounts => ({
      ...prevCounts,
      ...counts
    }));
  }, [orders]);

  useEffect(() => {
    if (product?.locations) {
      const hasValidInputs = product.locations.some(
        location => counts[location.id] && counts[location.id] > 0
      );

      setIsButtonDisabled(!hasValidInputs); // Enable the button if at least one input is valid
    }
  }, [counts, product]);

  const handleCountChange = (itemId: number, value: number, max: number) => {
    const validValue = Math.min(value, max);
    setCounts(prevCounts => ({
      ...prevCounts,
      [itemId]: validValue
    }));
  };

  const saveOrder = () => {
    if (product) {
      const invalidLocations = product.locations.filter(
        location => !counts[location.id] || counts[location.id] <= 0
      );

      if (invalidLocations.length === product.locations.length) {
        message.error(t('orders.invalid_order_message'));

        return;
      }

      product.locations.forEach(location => {
        const count = counts[location.id];

        if (count && count > 0) {
          dispatch(
            addProductToOrder({
              id: product.id,
              row_id: location.id,
              row_name: location.name,
              price: product.price * count,
              name: product.name || '',
              count
            })
          );
        }
      });

      onClose();
    }
  };

  const items: DescriptionsProps['items'] = [
    {
      label: t('product.code'),
      children: product?.code,
      key: 'code'
    },
    {
      label: t('product.barcode'),
      children: product?.barcode,
      key: 'barcode'
    },
    {
      label: t('product.price'),
      children: formatPrice(product?.price),
      key: 'price'
    },
    {
      label: t('product.total'),
      children: product?.count,
      key: 'count'
    }
  ];

  return (
    <Modal
      title={`${t('product.product_name')}: ${product?.name}`}
      footer={null}
      open
      onCancel={onClose}
    >
      <Descriptions
        style={{ marginTop: 24 }}
        layout="vertical"
        bordered
        column={1}
        size="small"
        items={items}
      />
      <Divider>{t('common.locations')}</Divider>
      <List
        dataSource={product?.locations}
        footer={
          <Button disabled={isButtonDisabled} onClick={saveOrder}>
            {t('orders.add_to_order')}
          </Button>
        }
        renderItem={item => (
          <List.Item key={item.id} style={{ borderBottom: '1px solid #ccc' }}>
            <List.Item.Meta
              style={{ display: 'flex' }}
              title={item.name}
              description={
                <Text>
                  {t('product.count')}: {item.count}
                </Text>
              }
            />
            <Flex gap={8}>
              <Input
                type="number"
                addonAfter={
                  <Button
                    onClick={() =>
                      handleCountChange(item.id, item.count, item.count)
                    }
                    type="text"
                    size="small"
                  >
                    max
                  </Button>
                }
                value={counts[item.id] ?? 0}
                style={{ width: 140 }}
                placeholder={t('product.count')}
                min={0}
                max={item.count}
                onChange={e =>
                  handleCountChange(item.id, Number(e.target.value), item.count)
                }
              />
            </Flex>
          </List.Item>
        )}
      />
    </Modal>
  );
};

export default ProductModal;
