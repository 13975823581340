import { App, Button, Dropdown, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import {
  selectPlanActionLoading,
  selectPlans,
  selectPlansLoading
} from 'app/features/plans/selectors';
import { deletePlan, getPlans } from 'app/features/plans/slice';
import { IPlan } from 'app/features/plans/types';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuProps } from 'antd/lib';
import {
  DeleteOutlined,
  ExclamationCircleFilled,
  EyeOutlined,
  MoreOutlined
} from '@ant-design/icons';

import { PlanModal, PlansHeader } from './components';

const PAGE_LIMIT = 10;

const { Text } = Typography;

const Plans = () => {
  const { t } = useTranslation();
  const { modal } = App.useApp();
  const dispatch = useAppDispatch();
  const plans = useAppSelector(selectPlans);
  const loading = useAppSelector(selectPlansLoading);
  const actionLoading = useAppSelector(selectPlanActionLoading);
  const [page, setPage] = useState(0);
  const [planId, setPlanId] = useState<string | null>(null);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    dispatch(getPlans({ limit: PAGE_LIMIT, page }));
  }, [dispatch, page]);

  const openDeleteWarning = (id: string) => {
    modal.confirm({
      title: t('plans.delete_plan_warning'),
      icon: <ExclamationCircleFilled />,
      cancelText: t('common.no'),
      okText: t('common.yes'),
      onOk: () => onDeletePlan(id),
      okButtonProps: { loading: actionLoading }
    });
  };

  const onDeletePlan = async (id: string) => {
    await dispatch(deletePlan(id)).then(res => {
      if (res.meta.requestStatus === 'fulfilled') {
        modal.success({ title: t('plans.success_plan_delete') });
        dispatch(getPlans({ limit: PAGE_LIMIT, page: 0 }));
      }
    });
  };

  const columns: ColumnsType<IPlan> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: t('plans.plan'),
      dataIndex: 'plan',
      key: 'plan'
    },
    {
      title: t('plans.start_date'),
      dataIndex: 'start_date',
      key: 'start_date'
    },
    {
      title: t('plans.end_date'),
      dataIndex: 'end_date',
      key: 'end_date'
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      width: 73,
      render: (_, { id }) => {
        const items: MenuProps['items'] = [
          {
            label: <Text>{t('common.view')}</Text>,
            icon: <EyeOutlined style={{ fontSize: 16 }} />,
            key: 'edit_plan',
            onClick: () => setPlanId(id)
          },
          {
            label: (
              <Text style={{ color: 'var(--danger)' }}>
                {t('common.delete')}
              </Text>
            ),
            icon: <DeleteOutlined style={{ fontSize: 16 }} />,
            key: 'delete_plan',
            onClick: () => openDeleteWarning(id)
          }
        ];

        return (
          <Dropdown menu={{ items }} trigger={['hover']}>
            <Button
              size="large"
              icon={<MoreOutlined style={{ fontSize: 18 }} />}
            />
          </Dropdown>
        );
      }
    }
  ];

  const onCloseModal = () => {
    setOpenModal(false);
    setPlanId(null);
  };

  return (
    <>
      <PlansHeader
        onChangePage={setPage}
        openModal={() => setOpenModal(true)}
      />
      <Table
        loading={loading}
        columns={columns}
        dataSource={plans?.results}
        rowKey="id"
        bordered
        pagination={{
          showSizeChanger: false,
          current: page + 1,
          onChange: page => {
            setPage(page - 1);
          },
          total: plans?.total || 0,
          pageSize: PAGE_LIMIT
        }}
      />
      {(!!planId || openModal) && (
        <PlanModal id={planId} onClose={onCloseModal} />
      )}
    </>
  );
};

export default Plans;
