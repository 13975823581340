import {
  App,
  Button,
  DatePicker,
  Flex,
  Form,
  Input,
  InputNumber,
  Radio,
  RadioChangeEvent,
  Typography,
  Upload
} from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { FC, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import {
  createManager,
  getAllManagers,
  setManagerById
} from 'app/features/managers/slice';
import { ManagerActionType } from 'app/features/managers/types';
import { selectManagersLoadingAction } from 'app/features/managers/selectors';
import { UploadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { useUpload } from 'hooks/useUpload';
import { StyledCreateModal } from './styled';
import { CURRENCY } from 'utils/price';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

const CreateManager: FC<IProps> = ({ isOpen, onClose }) => {
  // form
  const [form] = Form.useForm();
  // dispatch
  const dispatch = useAppDispatch();
  const { onUploadFile, clearUploadQueue } = useUpload();
  // selectors
  const loadingAction = useAppSelector(selectManagersLoadingAction);
  const [availability, setAvailability] = useState(true);
  const [searchParams] = useSearchParams();
  const isPlanFilled = !!Form.useWatch('plan', form);
  const { t } = useTranslation();
  const { modal } = App.useApp();
  const { Dragger } = Upload;

  const handleCloseModal = () => {
    form.resetFields();
    clearUploadQueue();
    dispatch(setManagerById({}));
    onClose();
  };

  const onSave = async (values: Omit<ManagerActionType, 'id'>) => {
    await form.validateFields();

    const searchValue = new URLSearchParams(window.location.search).get(
      'search'
    );

    const pageValue = Number(searchParams.get('page'));
    const page = pageValue ? pageValue - 1 : 0;

    const isPlanFilled = !!values.plan;

    if (!isPlanFilled) {
      delete values.plan;
      delete values.duration;
      delete values.start_date;
    }

    if (isPlanFilled) {
      if (!values.duration || !values.start_date) {
        form.setFields([
          {
            name: 'duration',
            errors: [t('validation.required')]
          },
          {
            name: 'start_date',
            errors: [t('validation.required')]
          }
        ]);

        return;
      }
    }

    if (values.start_date) {
      values.start_date = dayjs(values.start_date).format('YYYY-MM-DD');
    }

    return dispatch(
      createManager({
        ...values,
        file_id: values.file_id
          ? values.file_id[0]?.response.data.data.id
          : undefined,
        active: availability
      })
    ).then(res => {
      if (res.meta.requestStatus === 'fulfilled') {
        handleCloseModal();
        dispatch(
          getAllManagers({
            search: searchValue,
            page: page
          })
        );
        modal.success({ title: t('managers.manager_was_create') });
      }
    });
  };

  const onChangeAvailability = (e: RadioChangeEvent) => {
    setAvailability(e.target.value);
  };

  return (
    <StyledCreateModal
      width={800}
      title={t('managers.create_manager')}
      open={isOpen}
      onCancel={handleCloseModal}
      footer={[
        <Button key="cancel" onClick={async () => handleCloseModal()}>
          {t('common.cancel')}
        </Button>,
        <Button
          key="save"
          type="primary"
          onClick={form.submit}
          loading={loadingAction}
        >
          {t('common.save')}
        </Button>
      ]}
    >
      <Form form={form} onFinish={onSave} layout="vertical" size="large">
        <Form.Item
          name="first_name"
          label={t('managers.first_name')}
          rules={[{ required: true }]}
        >
          <Input placeholder={t('managers.first_name_placeholder')} />
        </Form.Item>
        <Form.Item
          rules={[{ required: true }]}
          name="last_name"
          label={t('managers.last_name')}
        >
          <Input placeholder={t('managers.last_name_placeholder')} />
        </Form.Item>
        <Form.Item
          name="email"
          label={t('managers.email')}
          rules={[{ type: 'email' }]}
          messageVariables={{
            label: t('validation.input_is_not_valid_email')
          }}
        >
          <Input
            autoComplete="new-email"
            placeholder={t('managers.email_placeholder')}
          />
        </Form.Item>
        <Form.Item
          name="phone"
          label={t('managers.phone')}
          rules={[
            { required: true },
            {
              // eslint-disable-next-line no-useless-escape
              pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
              message: t('validation.enter_phone_number')
            }
          ]}
        >
          <Input placeholder={t('managers.phone_placeholder')} />
        </Form.Item>
        <Form.Item
          name="password"
          label={t('managers.password')}
          rules={[
            {
              required: true
            },
            {
              min: 8,
              message: t('validation.password_warning')
            }
          ]}
        >
          <Input.Password
            autoComplete="new-password"
            placeholder={t('managers.password_placeholder')}
          />
        </Form.Item>
        <Flex flex={1} vertical gap={24}>
          <Typography.Text>{t('managers.plan_description')}</Typography.Text>
          <Flex flex={1} gap={12}>
            <Form.Item
              style={{ flex: 1 }}
              name="plan"
              rules={[
                {
                  // eslint-disable-next-line no-useless-escape
                  pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
                  message: t('validation.only_numbers')
                }
              ]}
            >
              <Input
                style={{ width: '100%' }}
                prefix={CURRENCY}
                placeholder={t('managers.plan_price')}
              />
            </Form.Item>
            <Form.Item
              style={{ flex: 1 }}
              name="duration"
              rules={[
                { required: isPlanFilled, message: t('validation.required') },
                {
                  pattern: /^\d+$/,
                  message: t('validation.only_numbers')
                }
              ]}
            >
              <InputNumber
                disabled={!isPlanFilled}
                style={{ width: '100%' }}
                min={1}
                placeholder={t('managers.plan_duration')}
              />
            </Form.Item>
            <Form.Item
              style={{ flex: 1 }}
              name="start_date"
              rules={[{ required: isPlanFilled }]}
            >
              <DatePicker
                disabled={!isPlanFilled}
                placeholder={t('managers.start_date')}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Flex>
        </Flex>

        <Form.Item
          label={t('managers.manager_image')}
          name="file_id"
          valuePropName="fileList"
          getValueFromEvent={e => e.fileList || []}
        >
          <Dragger
            accept="image/*"
            customRequest={onUploadFile}
            maxCount={1}
            listType="picture"
          >
            <Button icon={<UploadOutlined />}>
              {t('managers.upload_image')}
            </Button>
          </Dragger>
        </Form.Item>
        <Radio.Group onChange={onChangeAvailability} value={availability}>
          <Radio value={true}>{t('managers.active')}</Radio>
          <Radio value={false}>{t('managers.inactive')}</Radio>
        </Radio.Group>
      </Form>
    </StyledCreateModal>
  );
};

export default memo(CreateManager);
