import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { client } from 'api';
import { AxiosError } from 'axios';
import { ErrorType, showErrorMessage } from 'helpers/errors';

import { AnalyticsState } from './types';

const initialState: AnalyticsState = {
  customers_order_count: 0,
  monthly_order_count: 0,
  customers_order: null,
  monthly_order: null,
  loading: false
};

export const getAllAnalytics = createAsyncThunk(
  'analytics/getAllAnalytics',
  async () => {
    try {
      const res = await client.get('orders/charts-data');

      return res.data.data;
    } catch (error) {
      showErrorMessage(error as AxiosError<ErrorType>);
      throw error;
    }
  }
);

const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAllAnalytics.pending, state => {
        state.loading = true;
      })
      .addCase(getAllAnalytics.fulfilled, (state, action) => {
        state.monthly_order = action.payload.order_month;
        state.customers_order = action.payload.customers_order;
        state.customers_order_count = action.payload.customers_count;
        state.monthly_order_count = action.payload.orders_count;
        state.loading = false;
      })
      .addCase(getAllAnalytics.rejected, state => {
        state.loading = false;
      });
  }
});

export default analyticsSlice.reducer;
