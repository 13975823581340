import { FC, useRef } from 'react';
import {
  Modal,
  Descriptions,
  Form,
  InputNumber,
  Flex,
  Button,
  App
} from 'antd';
import { DescriptionsProps } from 'antd/lib';
import { selectLocationModalType } from 'app/features/locationModal/selectors';
import { setLocationModalType } from 'app/features/locationModal/slice';
import { ILocations } from 'app/features/locations/types';
import { selectProductById } from 'app/features/products/selectors';
import {
  assignProduct,
  decrementProductFromRow,
  setProductByCode
} from 'app/features/products/slice';
import { AssignProductBody } from 'app/features/products/types';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useTranslation } from 'react-i18next';

interface IProps {
  location?: ILocations;
  id: string | null;
  onSaveAction: () => void;
}

const UpdateModal: FC<IProps> = ({ location, id, onSaveAction }) => {
  const { t } = useTranslation();
  const { modal } = App.useApp();
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const modalType = useAppSelector(selectLocationModalType);
  const product = useAppSelector(selectProductById(id));
  const formType = useRef('');

  const onFinish = async (value: AssignProductBody) => {
    try {
      await form.validateFields();

      if (formType.current === 'increment') {
        if (Number(value.count) > Number(product?.unassigned)) {
          return modal.error({
            title: t('validation.something_went_wrong'),
            content: t('validation.invalid_increment')
          });
        }

        return dispatch(
          assignProduct({
            product_id: product?.id,
            row_id: location?.id,
            count: value.count
          })
        ).then(res => {
          if (res.meta.requestStatus === 'fulfilled') {
            onSaveAction();
            form.resetFields();
            dispatch(setLocationModalType(null));
            dispatch(setProductByCode(null));
            modal.success({ title: t('product.product_was_incremented') });
          }
        });
      }

      if (formType.current === 'assign') {
        if (Number(value.count) > Number(product?.unassigned)) {
          return modal.error({
            title: t('validation.something_went_wrong'),
            content: t('validation.invalid_increment')
          });
        }

        return dispatch(
          assignProduct({
            product_id: product?.id,
            row_id: location?.id,
            count: value.count
          })
        ).then(res => {
          if (res.meta.requestStatus === 'fulfilled') {
            onSaveAction();
            dispatch(setLocationModalType(null));
            dispatch(setProductByCode(null));
            form.resetFields();
            modal.success({ title: t('product.product_was_assigned') });
          }
        });
      }

      if (Number(product?.locations[0]?.count) < Number(value.count)) {
        return modal.error({
          title: t('validation.something_went_wrong'),
          content: t('validation.invalid_decrement')
        });
      }

      return dispatch(
        decrementProductFromRow({
          product_id: product?.id,
          row_id: location?.id,
          count: value.count
        })
      ).then(res => {
        if (res.meta.requestStatus === 'fulfilled') {
          onSaveAction();
          form.resetFields();
          dispatch(setLocationModalType(null));
          dispatch(setProductByCode(null));
          modal.success({ title: t('product.product_was_decremented') });
        }
      });
    } catch (error) {
      modal.success({ title: error as string });
    }
  };

  const items: DescriptionsProps['items'] = [
    {
      label: t('product.code'),
      children: product?.code,
      key: 'code'
    },
    {
      label: t('product.barcode'),
      children: product?.barcode,
      key: 'barcode'
    },
    {
      label: t('product.unassigned'),
      children: product?.unassigned,
      key: 'unassigned'
    },
    {
      label: t('product.assigned_to_this_row'),
      children: product?.locations[0]?.count || 0,
      key: 'unassigned'
    },
    {
      label: t('product.total'),
      children: product?.total_amount,
      key: 'unassigned'
    }
  ];

  return (
    <div>
      <Modal
        title={t(`locations.${modalType}_product`)}
        open
        footer={null}
        onCancel={() => {
          dispatch(setProductByCode(null));
          dispatch(setLocationModalType(null));
        }}
      >
        {product && (
          <>
            <Descriptions
              layout="vertical"
              bordered
              column={1}
              size="small"
              items={items}
            />
            <Form
              form={form}
              onFinish={onFinish}
              style={{ marginTop: 20 }}
              layout="vertical"
              initialValues={{ count: 1 }}
            >
              <Form.Item
                required
                name="count"
                label={t('product.count_required')}
                rules={[
                  {
                    required: true,
                    message: t('product.count_required')
                  }
                ]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  min={1}
                  type="number"
                  size="middle"
                />
              </Form.Item>
              <Flex justify="space-between">
                <Button
                  type="default"
                  htmlType="button"
                  onClick={() => {
                    dispatch(setLocationModalType('product'));
                  }}
                >
                  {t('common.cancel')}
                </Button>
                {product?.is_match ? (
                  <Flex gap={12}>
                    <Button
                      onClick={() => {
                        formType.current = 'decrement';
                        form.submit();
                      }}
                      htmlType="button"
                      danger
                    >
                      {t('product.decrement')}
                    </Button>
                    <Button
                      onClick={() => {
                        formType.current = 'increment';
                        form.submit();
                      }}
                      htmlType="button"
                      type="primary"
                    >
                      {t('product.increment')}
                    </Button>
                  </Flex>
                ) : (
                  <Button
                    onClick={() => {
                      formType.current = 'assign';
                      form.submit();
                    }}
                    type="primary"
                  >
                    {t('locations.assign')}
                  </Button>
                )}
              </Flex>
            </Form>
          </>
        )}
      </Modal>
    </div>
  );
};

export default UpdateModal;
