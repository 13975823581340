import { Flex, Modal } from 'antd';
import styled from 'styled-components';

export const StyledModal = styled(Modal)`
  &&&&&& {
    height: 80vh;
    padding: 0;
    overflow-y: auto;
    border-radius: 8px;
  }
  .ant-modal-content {
    border-radius: 8px;
  }
`;

export const LocationStepStyled = styled(Flex)`
  width: 100%;
  .ant-steps-item-icon {
    display: none;
  }
`;
