import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import {
  Button,
  Dropdown,
  MenuProps,
  Table,
  Tag,
  Typography,
  Image
} from 'antd';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useSearchParams } from 'react-router-dom';
import {
  selectManagersData,
  selectManagersDataTotal,
  selectManagersLimit,
  selectManagersLoading
} from 'app/features/managers/selectors';
import { getAllManagers } from 'app/features/managers/slice';
import { IManagers } from 'app/features/managers/types';
import { EyeOutlined, LockOutlined, MoreOutlined } from '@ant-design/icons';
import { BASE_URL } from 'api';

import defaultImg from '../../assets/images/default_image.png';
import { _debounce } from 'utils/helpers';
import ManagersHeader from './components/ManagersHeader';
import PasswordModal from './components/PasswordModal';
import CreateManager from './components/CreateManager';

const debounce = _debounce();
const { Text } = Typography;

const Managers = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const [managersModal, setManagersModal] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);
  const [managerId, setManagerId] = useState<null | number>(null);
  const [page, setPage] = useState(0);
  const loading = useAppSelector(selectManagersLoading);
  const managersData = useAppSelector(selectManagersData);
  const total = useAppSelector(selectManagersDataTotal);
  const limit = useAppSelector(selectManagersLimit);
  const onChangePage = (page: number) => setPage(page);

  const columns: ColumnsType<IManagers> = [
    {
      title: t('managers.image'),
      dataIndex: 'avatar',
      key: 'avatar',
      width: 80,
      render: file => (
        <Image
          width={60}
          height={60}
          preview={false}
          style={{ borderRadius: 12, objectFit: 'cover' }}
          src={
            file?.name ? `${BASE_URL}/assets/uploads/${file?.name}` : defaultImg
          }
        />
      )
    },
    {
      title: t('managers.first_name'),
      dataIndex: 'first_name',
      key: 'first_name'
    },
    {
      title: t('managers.last_name'),
      dataIndex: 'last_name',
      key: 'last_name'
    },
    {
      title: t('managers.email'),
      dataIndex: 'email',
      key: 'email',
      render: (text: string) => {
        return (
          <>
            <a href={`mailto: ${text}`}>{text}</a>
          </>
        );
      }
    },
    {
      title: t('managers.phone'),
      dataIndex: 'phone',
      key: 'phone'
    },
    {
      title: t('managers.created_at'),
      dataIndex: 'created_at',
      key: 'created_at',
      render: item => dayjs(item).format('DD-MM-YYYY')
    },
    {
      title: t('managers.availability'),
      dataIndex: 'active',
      key: 'active',
      render: (active: boolean) => {
        if (active) {
          return <Tag color="green">{t('managers.active')}</Tag>;
        }

        return <Tag color="red">{t('managers.inactive')}</Tag>;
      }
    },
    {
      title: '',
      dataIndex: 'details',
      key: 'details',
      width: 73,
      render: (_, row) => {
        const { id } = row;
        const items: MenuProps['items'] = [
          // {
          //   label: <Text>{t('managers.update_information')}</Text>,
          //   icon: <EditOutlined style={{ fontSize: 14 }} />,
          //   key: 'update_information',
          //   onClick: () => {
          //     setManagerId(id);
          //     setManagersModal(true);
          //   }
          // },
          {
            label: <Text>{t('managers.update_password')}</Text>,
            icon: <LockOutlined style={{ fontSize: 14 }} />,
            key: 'update_password',
            onClick: () => {
              setManagerId(id);
              setPasswordModal(true);
            }
          },
          {
            label: <Text>{t('managers.view_manager_info')}</Text>,
            icon: <EyeOutlined style={{ fontSize: 14 }} />,
            key: 'view_manager_info',
            onClick: () => navigate(`/managers/details/${row.id}`)
          }
        ];

        return (
          <Dropdown menu={{ items }} trigger={['click']}>
            <Button
              size="large"
              icon={<MoreOutlined style={{ fontSize: 18 }} />}
            />
          </Dropdown>
        );
      }
    }
  ];

  const onCloseManagerModal = () => {
    setManagerId(null);
    setManagersModal(false);
  };

  const onClosePasswordModal = () => {
    setManagerId(null);
    setPasswordModal(false);
  };

  useEffect(() => {
    const searchValue = searchParams.get('search');

    debounce(() => {
      dispatch(
        getAllManagers({
          search: searchValue,
          page: page
        })
      );
    });
  }, [dispatch, page, searchParams]);

  return (
    <>
      <ManagersHeader
        loading={loading}
        onChangePage={onChangePage}
        isOpenModal={() => setManagersModal(true)}
      />
      <Table
        loading={loading}
        columns={columns}
        dataSource={managersData}
        rowKey="id"
        bordered
        pagination={{
          showSizeChanger: false,
          current: page + 1,
          onChange: page => {
            onChangePage(page - 1);
          },
          total,
          pageSize: limit
        }}
      />
      <CreateManager isOpen={managersModal} onClose={onCloseManagerModal} />
      <PasswordModal
        isOpen={passwordModal}
        onClose={onClosePasswordModal}
        id={managerId}
      />
    </>
  );
};

export default Managers;
