import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

const getLocations = (state: RootState) => state.locations;

export const selectLocationsData = createSelector(
  [getLocations],
  location => location.locations
);

export const selectLocationsTotal = createSelector(
  [getLocations],
  location => location.total
);

export const selectLocationById = (id: number | null) =>
  createSelector([getLocations], location => (id ? location.byId[id] : null));

export const selectLocationByIdLoading = createSelector(
  [getLocations],
  location => location.loadingById
);

export const selectLocationsLoading = createSelector(
  [getLocations],
  location => location.loading
);

export const selectLocationsLoadingAction = createSelector(
  [getLocations],
  location => location.loadingAction
);

export const selectSelectedLocationId = createSelector(
  [getLocations],
  location => location.selectedLocationId
);
