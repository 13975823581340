import { Flex, Table, Image, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import {
  selectAllProducts,
  selectAllProductsLoading,
  selectProductsLimit,
  selectProductsTotal
} from 'app/features/products/selectors';
import { getAllProducts, setProductById } from 'app/features/products/slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditOutlined } from '@ant-design/icons';
import { LocationsType } from 'app/features/products/types';
import { useSearchParams } from 'react-router-dom';
import { BASE_URL } from 'api';

import defaultImg from '../../assets/images/default_image.png';
import { ProductHeader, ProductModal } from './components';
import { formatPrice } from 'utils/price';

type columnsType = {
  id: number;
  code: string;
  barcode: string;
  total_amount: string;
  files: { name: string; id: string }[];
  locations: LocationsType[];
};

const { Text } = Typography;

const Products = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const products = useAppSelector(selectAllProducts);
  const total = useAppSelector(selectProductsTotal);
  const limit = useAppSelector(selectProductsLimit);
  const loading = useAppSelector(selectAllProductsLoading);
  const [openModal, setOpenModal] = useState(false);
  const [productId, setProductId] = useState(0);
  const [searchParams] = useSearchParams();
  const [page, setPage] = useState(0);

  const onCloseModal = () => {
    setProductId(0);
    setOpenModal(false);
    dispatch(setProductById({}));
  };

  const filterValue = searchParams.get('filter');

  const onChangePage = (value: number) => {
    setPage(value);
  };

  useEffect(() => {
    const search = new URLSearchParams(window.location.search).get('search');

    dispatch(
      getAllProducts({
        search,
        page,
        locations: filterValue
      })
    );
  }, [page, dispatch, filterValue]);

  const columns: ColumnsType<columnsType> = [
    {
      title: t('product.image'),
      dataIndex: 'files',
      key: 'files',
      width: 80,
      render: file => (
        <Image
          preview={!!file?.length}
          width={60}
          height={60}
          style={{ borderRadius: 12, objectFit: 'cover' }}
          src={
            Array.isArray(file) && file[0]?.name
              ? `${BASE_URL}/assets/uploads/${file[0]?.name}`
              : defaultImg
          }
        />
      )
    },
    {
      title: t('product.name'),
      dataIndex: 'name',
      key: 'name',
      render: name => (name ? <Text copyable>{name}</Text> : '')
    },
    {
      title: t('product.code'),
      dataIndex: 'code',
      key: 'code',
      render: code =>
        code ? (
          <Text style={{ whiteSpace: 'nowrap' }} copyable>
            {code}
          </Text>
        ) : (
          ''
        )
    },
    {
      title: t('product.barcode'),
      dataIndex: 'barcode',
      key: 'barcode',
      align: 'center',
      render: barcode =>
        barcode ? (
          <Text style={{ whiteSpace: 'nowrap' }} copyable>
            {barcode}
          </Text>
        ) : (
          ''
        )
    },
    {
      title: t('product.created_at'),
      dataIndex: 'created_at',
      key: 'created_at',
      align: 'center',
      render: item => (
        <Text style={{ whiteSpace: 'nowrap' }}>
          {dayjs(item).format('DD-MM-YYYY')}
        </Text>
      )
    },
    {
      title: t('product.total'),
      dataIndex: 'total_amount',
      key: 'total_amount',
      align: 'center'
    },
    {
      title: t('product.price'),
      dataIndex: 'price',
      key: 'price',
      render: formatPrice
    },
    {
      title: t('product.assigned'),
      dataIndex: 'count',
      key: 'count',
      align: 'center'
    },
    {
      title: t('product.unassigned'),
      dataIndex: 'unassigned',
      key: 'unassigned',
      align: 'center'
    },
    {
      title: '',
      dataIndex: 'id',
      width: 40,
      key: 'id',
      align: 'center',
      render: (id: number) => {
        return (
          <Flex justify="space-between">
            <EditOutlined
              onClick={() => {
                setOpenModal(true);
                setProductId(id);
              }}
              style={{ fontSize: 20 }}
            />
          </Flex>
        );
      }
    }
  ];

  return (
    <>
      <ProductHeader onChangePage={onChangePage} title={t('common.products')} />
      <Table
        bordered
        rowKey="id"
        loading={loading}
        columns={columns}
        dataSource={products || []}
        pagination={{
          showSizeChanger: false,
          current: page + 1,
          onChange: page => {
            setPage(page - 1);
          },
          total,
          pageSize: limit
        }}
      />
      {openModal && (
        <ProductModal
          id={productId}
          onClose={onCloseModal}
          isOpen={openModal}
        />
      )}
    </>
  );
};

export default Products;
