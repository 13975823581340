import { Modal } from 'antd';
import styled from 'styled-components';

export const StyledCreateModal = styled(Modal)`
  &&&&&& {
    height: 80vh;
    padding: 0;
    overflow-y: auto;
    border-radius: 8px;
  }
  .ant-modal-content {
    border-radius: 8px;
  }
`;
