import { ILocations } from 'app/features/locations/types';

export type TreeDataType = {
  title: string;
  value: string;
  children: TreeDataType[];
  disabled?: boolean;
};

export const transformLocationsToTreeData = (
  locations: ILocations[]
): TreeDataType[] => {
  const map = new Map<number, TreeDataType>();

  const transformLocation = (location: ILocations): TreeDataType => ({
    title: location.name,
    value: location.id.toString(),
    children: location.children ? location.children.map(transformLocation) : [],
    disabled: location.children && location.children.length > 0
  });

  locations?.forEach(location => {
    map.set(location.id, transformLocation(location));
  });

  const treeData: TreeDataType[] = [];

  locations?.forEach(location => {
    const node = map.get(location.id) as TreeDataType;

    if (location.parent_id === null) {
      treeData.push(node);
    } else {
      const parentNode = map.get(location.parent_id);

      if (parentNode) {
        if (!parentNode.children) {
          parentNode.children = [];
        }

        parentNode.children.push(node);
      }
    }
  });

  return treeData;
};
