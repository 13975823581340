import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { client } from 'api';
import { RootState } from 'app/store';
import { AxiosError } from 'axios';
import { ErrorType, showErrorMessage } from 'helpers/errors';

import { OrdersState } from './types';
import { selectOrdersLimit, selectOrdersPage } from './selectors';

const initialState: OrdersState = {
  orders: { results: [], total: 0 },
  orderById: null,
  loadingById: false,
  loading: false,
  page: 0,
  limit: 10
};

interface GetAllOrdersParams {
  search?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  worker?: string | null;
}
export const getAllOrders = createAsyncThunk(
  'warehouseOrders/getAllOrders',
  async (params: GetAllOrdersParams | undefined, { getState }) => {
    try {
      const state = getState() as RootState;
      const limit = selectOrdersLimit()(state);
      const page = selectOrdersPage()(state);
      const res = await client.get('orders', {
        params: {
          name: params?.search,
          startDate: params?.startDate,
          endDate: params?.endDate,
          worker: params?.worker,
          page,
          limit
        }
      });

      return res.data.data;
    } catch (error) {
      showErrorMessage(error as AxiosError<ErrorType>);
      throw error;
    }
  }
);

export const getOrderById = createAsyncThunk(
  'warehouseOrders/getOrderById',
  async (id: number, { dispatch }) => {
    try {
      const response = await client.get(`/orders/${id}`);

      return dispatch(setOrderById(response.data.data));
    } catch (error) {
      showErrorMessage(error as AxiosError<ErrorType>);
      throw error;
    }
  }
);

const warehouseOrdersSlice = createSlice({
  name: 'warehouseOrders',
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setOrderById: (state, action) => {
      state.orderById = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getAllOrders.pending, state => {
        state.loading = true;
      })
      .addCase(getAllOrders.fulfilled, (state, action) => {
        state.orders = action.payload;
        state.loading = false;
      })
      .addCase(getAllOrders.rejected, state => {
        state.loading = false;
      })
      .addCase(getOrderById.pending, state => {
        state.loadingById = true;
      })
      .addCase(getOrderById.fulfilled, state => {
        state.loadingById = false;
      })
      .addCase(getOrderById.rejected, state => {
        state.loadingById = false;
      });
  }
});

export const { setPage, setOrderById } = warehouseOrdersSlice.actions;
export default warehouseOrdersSlice.reducer;
