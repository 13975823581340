import { FC, ReactNode, useState } from 'react';
import { Button, Flex, List, Tag, Typography } from 'antd';
import { selectCustomerById } from 'app/features/customers/selectors';
import { useAppSelector } from 'app/hooks';
import { useTranslation } from 'react-i18next';

import { DebtModal } from '..';

type IListData = {
  title: string;
  description: ReactNode;
};

interface IProps {
  id: string;
}

const { Text } = Typography;

const GeneralInfo: FC<IProps> = ({ id }) => {
  const { t } = useTranslation();
  const [isOpenModal, setOpenMOdal] = useState(false);
  const customerById = useAppSelector(selectCustomerById(id));

  const listData: IListData[] = [
    { title: t('customers.name'), description: customerById?.name || '_____' },
    { title: t('customers.code'), description: customerById?.code || '_____' },
    {
      title: t('customers.email'),
      description: customerById?.customers_info.email || '_____'
    },
    {
      title: t('customers.phone'),
      description: customerById?.customers_info.phone || '_____'
    },
    {
      title: t('customers.address'),
      description: customerById?.customers_info.address || '_____'
    },
    {
      title: t('customers.managers'),
      description: customerById?.managers.length
        ? customerById?.managers.map((item, index) => (
            <a href={`/managers/details/${item.id}`} key={index}>
              <Tag color="blue" bordered={false}>
                {item.first_name} {item.last_name}
              </Tag>
            </a>
          ))
        : '_____'
    },
    {
      title: t('common.debt'),
      description: (
        <Flex justify="space-between">
          {customerById?.total_debt}$
          {!!customerById?.total_debt && (
            <Button onClick={() => setOpenMOdal(true)}>
              {t('customers.pay_off_debt')}
            </Button>
          )}
        </Flex>
      )
    }
  ];

  return (
    <div>
      <List
        size="small"
        style={{ backgroundColor: 'white' }}
        bordered
        dataSource={listData}
        renderItem={item => (
          <List.Item key={item.title}>
            <List.Item.Meta
              title={<Text>{item.title}</Text>}
              description={item.description}
            />
          </List.Item>
        )}
      />
      {!!(isOpenModal && customerById?.id) && (
        <DebtModal
          customerId={customerById.id}
          customerCode={customerById?.code}
          onClose={() => setOpenMOdal(false)}
        />
      )}
    </div>
  );
};

export default GeneralInfo;
