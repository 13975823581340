import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  getAllCustomers,
  removeCustomer,
  uploadCustomersDebtExcel,
  uploadCustomersExcel
} from 'app/features/customers/slice';
import {
  selectCustomersData,
  selectCustomersDataTotal,
  selectCustomersLimit,
  selectCustomersLoading,
  selectCustomersLoadingAction
} from 'app/features/customers/selectors';
import {
  App,
  Button,
  Dropdown,
  Flex,
  Input,
  MenuProps,
  Table,
  Upload
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { ICustomers } from 'app/features/customers/types';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  EyeOutlined,
  MoreOutlined,
  PlusOutlined
} from '@ant-design/icons';
import dayjs from 'dayjs';

import { _debounce } from 'utils/helpers';
import { PageWrapper } from 'components/ui';
import { DropdownIcon } from './styled';
import { CustomersModal } from './components';

const { Search } = Input;

const debounce = _debounce();

const Customers = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { modal } = App.useApp();
  const navigate = useNavigate();
  const customersData = useAppSelector(selectCustomersData);
  const total = useAppSelector(selectCustomersDataTotal);
  const loading = useAppSelector(selectCustomersLoading);
  const loadingAction = useAppSelector(selectCustomersLoadingAction);
  const limit = useAppSelector(selectCustomersLimit);
  const [customerModal, setCustomerModal] = useState(false);
  const [customerId, setCustomerId] = useState<null | number>(null);
  const [page, setPage] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();

  const onCloseCustomerModal = () => {
    setCustomerId(null);
    setCustomerModal(false);
  };

  const onSearch = e => {
    const searchValue = e.target.value;

    onChangePage(0);

    if (searchValue) {
      searchParams.set('search', searchValue);
      setSearchParams(searchParams);
    } else {
      searchParams.delete('search');
      setSearchParams(searchParams);
    }
  };

  const handleFileUpload = async (file: File, type: 'customer' | 'debt') => {
    const action =
      type === 'customer' ? uploadCustomersExcel : uploadCustomersDebtExcel;

    const searchValue = searchParams.get('search');
    const response = await dispatch(action(file));

    if (response.meta.requestStatus === 'fulfilled') {
      modal.success({ title: t('common.excel_file_uploaded_successfully') });
      searchParams.delete('search');
      searchParams.set('page', '1');
      setSearchParams(searchParams);
      dispatch(getAllCustomers({ search: searchValue, page: 0 }));
    }
  };

  const onChangePage = (page: number) => setPage(page);

  const deleteCustomer = async (id: number) => {
    const searchValue = new URLSearchParams(window.location.search).get(
      'search'
    );

    dispatch(removeCustomer(id)).then(res => {
      if (res.meta.requestStatus === 'fulfilled') {
        dispatch(
          getAllCustomers({
            search: searchValue,
            page: page
          })
        );
        modal.success({ title: t('customers.customer_was_removed') });
      }
    });
  };

  const showDeleteConfirm = (id: number) => {
    modal.confirm({
      title: t('common.warning'),
      content: t('customers.delete_customer_warning'),
      icon: <ExclamationCircleFilled />,
      okText: t('common.yes'),
      cancelText: t('common.no'),
      okType: 'danger',
      onOk() {
        deleteCustomer(id);
      },
      okButtonProps: {
        loading: loadingAction
      }
    });
  };

  const columns: ColumnsType<ICustomers> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: t('customers.code'),
      dataIndex: 'code',
      key: 'code'
    },
    {
      title: t('customers.name'),
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: t('customers.email'),
      dataIndex: ['customers_info', 'email'],
      key: 'email',
      render: (text: string) => {
        return (
          <>
            <a href={`mailto: ${text}`}>{text}</a>
          </>
        );
      }
    },
    {
      title: t('customers.phone'),
      dataIndex: ['customers_info', 'phone'],
      key: 'phone'
    },
    {
      title: t('customers.created_at'),
      dataIndex: 'created_at',
      key: 'created_at',
      render: date => dayjs(date).format('YYYY-MM-DD')
    },
    {
      title: t('customers.managers'),
      dataIndex: 'managers',
      key: 'managers',
      render: (_, row) => {
        const managersMenu: MenuProps['items'] = row.managers.map(item => ({
          key: item.id,
          label: (
            <a
              href={`/managers/details/${item.id}`}
            >{`${item.first_name} ${item.last_name}`}</a>
          )
        }));

        if (!row.managers.length) {
          return '_____';
        }

        return (
          <Flex gap={12} align="center">
            <a
              href={`/managers/details/${row.managers[0]?.id}`}
            >{`${row.managers[0]?.first_name} ${row.managers[0]?.last_name}`}</a>
            {row.managers.length > 1 && (
              <Dropdown menu={{ items: managersMenu }}>
                <DropdownIcon />
              </Dropdown>
            )}
          </Flex>
        );
      }
    },
    {
      title: t('locations.action'),
      dataIndex: 'action',
      width: 100,
      key: 'Action',
      align: 'center',
      render: (_, { id }) => {
        const items = [
          {
            label: t('common.view'),
            icon: <EyeOutlined style={{ fontSize: 18 }} />,
            key: 'view_customer',
            onClick: () => navigate(`/customers/details/${id}`)
          },
          {
            label: t('common.update'),
            icon: <EditOutlined style={{ fontSize: 18, color: '#1677ff' }} />,
            key: 'update_customer',
            onClick: () => {
              setCustomerId(id);
              setCustomerModal(true);
            }
          },
          {
            label: t('common.delete'),
            icon: (
              <DeleteOutlined
                style={{ fontSize: 18, color: 'var(--text-danger)' }}
              />
            ),
            key: 'delete_customer',
            onClick: () => showDeleteConfirm(id)
          }
        ];

        return (
          <Dropdown menu={{ items }} trigger={['click']}>
            <Button
              size="large"
              icon={<MoreOutlined style={{ fontSize: 18 }} />}
            />
          </Dropdown>
        );
      }
    }
  ];

  useEffect(() => {
    const searchValue = searchParams.get('search');

    debounce(() => {
      dispatch(
        getAllCustomers({
          search: searchValue,
          page: page
        })
      );
    });
  }, [dispatch, page, searchParams]);

  return (
    <PageWrapper
      title={t('common.customers')}
      extra={[
        <Flex gap={16} key="customer-search" vertical>
          <Search
            value={searchParams.get('search') || undefined}
            placeholder={t('common.search')}
            onChange={onSearch}
            style={{ minWidth: 500 }}
          />
          <Flex gap={20} justify="flex-end">
            <Button
              onClick={() => setCustomerModal(true)}
              icon={<PlusOutlined />}
            >
              {t('customers.create_customers')}
            </Button>
            <Upload
              name="file"
              accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .ods"
              showUploadList={false}
              customRequest={({ file }) =>
                handleFileUpload(file as File, 'debt')
              }
            >
              <Button type="primary">{t('customers.upload_debt_excel')}</Button>
            </Upload>
            <Upload
              name="file"
              accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .ods"
              beforeUpload={() => false}
              showUploadList={false}
              customRequest={({ file }) =>
                handleFileUpload(file as File, 'customer')
              }
            >
              <Button type="primary">{t('customers.upload_excel')}</Button>
            </Upload>
          </Flex>
        </Flex>
      ]}
    >
      <Table
        loading={loading}
        columns={columns}
        dataSource={customersData}
        rowKey="id"
        bordered
        pagination={{
          showSizeChanger: false,
          current: page + 1,
          onChange: page => {
            onChangePage(page - 1);
          },
          total,
          pageSize: limit
        }}
      />
      {customerModal && (
        <CustomersModal id={customerId} onClose={onCloseCustomerModal} />
      )}
    </PageWrapper>
  );
};

export default Customers;
