import {
  App,
  Button,
  Col,
  DatePicker,
  Empty,
  Flex,
  Form,
  Image,
  Input,
  InputNumber,
  Radio,
  Row,
  Tag,
  Typography,
  Upload
} from 'antd';
import { getManagerById, updateManager } from 'app/features/managers/slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { ManagerActionType } from 'app/features/managers/types';
import { selectManagerById } from 'app/features/managers/selectors';
import { useParams } from 'react-router';
import { BASE_URL } from 'api';
import { RadioChangeEvent } from 'antd/lib';

import defaultImg from '../../../../assets/images/default_image.png';
import { useUpload } from 'hooks/useUpload';
import { StyledAction, StyledAvatar } from './styled';
import { CURRENCY } from 'utils/price';
import PlanItem from '../PlanHistory/PlanItem';

const { Text, Paragraph } = Typography;
const { Dragger } = Upload;

const GeneralInfo = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { modal } = App.useApp();
  const { id } = useParams();
  const [form] = Form.useForm();
  const { onUploadFile } = useUpload();
  const [availability, setAvailability] = useState(true);
  const [changeInfo, setChangeInfo] = useState(false);
  const manager = useAppSelector(selectManagerById(id));
  const managerAvatar = Form.useWatch('file_id', form) || manager?.avatar;
  const isPlanFilled = !!(Form.useWatch('plan', form) || manager?.plan);
  useEffect(() => {
    if (manager?.id) {
      const startDate = dayjs(manager.start_date);
      setAvailability(manager.active);
      form.setFieldsValue({
        first_name: manager.first_name,
        last_name: manager.last_name,
        email: manager.email,
        phone: manager.phone,
        plan: manager.plan,
        duration: manager.duration,
        start_date: startDate.isValid() ? startDate : null
      });
    }
  }, [manager, form, changeInfo]);

  const onChangeAvailability = (e: RadioChangeEvent) => {
    setAvailability(e.target.value);
  };

  const onSave = async (values: Omit<ManagerActionType, 'id'>) => {
    await form.validateFields();

    const newFile = values.file_id?.file?.response.data.data.id;

    if (manager && Object.keys(manager).length) {
      if (!values.plan) {
        delete values.plan;
        delete values.duration;
        delete values.start_date;
      }

      return dispatch(
        updateManager({
          ...values,
          plan: Number(values.plan),
          duration: Number(values.duration),
          start_date: dayjs(values.start_date).format('YYYY-MM-DD'),
          id: manager.id,
          file_id: newFile || manager.avatar?.id,
          active: availability
        })
      ).then(res => {
        if (res.meta.requestStatus === 'fulfilled') {
          modal.success({ title: t('managers.manager_was_update') });
          dispatch(getManagerById(Number(id)));
          setChangeInfo(false);
        }
      });
    }
  };

  const removeAvatar = () => {
    if (manager?.id) {
      dispatch(
        updateManager({
          id: manager.id,
          first_name: manager.first_name,
          last_name: manager.last_name,
          email: manager.email,
          phone: manager.phone,
          active: manager.active,
          plan: manager.plan,
          duration: manager.duration,
          start_date: dayjs(manager.start_date).format('YYYY-MM-DD')
        })
      ).then(res => {
        if (res.meta.requestStatus === 'fulfilled') {
          dispatch(getManagerById(Number(id)));
          setChangeInfo(false);
        }
      });
    }
  };

  if (!id) {
    return <Empty />;
  }

  return (
    <div>
      <StyledAction>
        {changeInfo ? (
          <Flex gap={16}>
            <Button onClick={() => setChangeInfo(false)}>
              {t('common.cancel')}
            </Button>
            <Button type="primary" onClick={form.submit}>
              {t('common.save')}
            </Button>
          </Flex>
        ) : (
          <Button type="primary" onClick={() => setChangeInfo(true)}>
            {t('managers.update_information')}
          </Button>
        )}
      </StyledAction>
      <Form onFinish={onSave} form={form} layout="vertical" size="large">
        <Row gutter={24}>
          <Col span={24}>
            <Row gutter={24}>
              <Col span={12}>
                <Flex
                  justify="center"
                  align="center"
                  vertical
                  style={{ marginBottom: changeInfo ? 0 : 24 }}
                >
                  <Image
                    width={160}
                    height={160}
                    preview={false}
                    style={{ borderRadius: 12, objectFit: 'cover' }}
                    src={
                      manager?.avatar?.name
                        ? `${BASE_URL}/assets/uploads/${manager?.avatar?.name}`
                        : defaultImg
                    }
                  />
                  {changeInfo && (
                    <StyledAvatar>
                      <Form.Item name="file_id" style={{ marginBottom: 0 }}>
                        <Dragger
                          accept="image/*"
                          maxCount={1}
                          customRequest={onUploadFile}
                          listType="picture"
                          style={{
                            background: 'white',
                            border: 'none',
                            height: 16
                          }}
                        >
                          <div className="action_image">
                            <Button style={{ padding: 0 }} type="link">
                              {t('managers.change_avatar')}
                            </Button>
                            {!!managerAvatar && (
                              <Button
                                onClick={e => {
                                  e.stopPropagation();
                                  removeAvatar();
                                }}
                                style={{ padding: 0 }}
                                type="link"
                                danger
                              >
                                {t('managers.remove_avatar')}
                              </Button>
                            )}
                          </div>
                        </Dragger>
                      </Form.Item>
                    </StyledAvatar>
                  )}
                </Flex>
              </Col>
              <Col span={6}>
                <Flex
                  style={{ height: '100%' }}
                  vertical
                  gap={12}
                  align="center"
                  justify="center"
                >
                  <Text>{t('managers.availability')}</Text>
                  {changeInfo ? (
                    <Radio.Group
                      onChange={onChangeAvailability}
                      value={availability}
                    >
                      <Radio value={true}>{t('managers.active')}</Radio>
                      <Radio value={false}>{t('managers.inactive')}</Radio>
                    </Radio.Group>
                  ) : (
                    <span>
                      {manager?.active ? (
                        <Tag color="green">{t('managers.active')}</Tag>
                      ) : (
                        <Tag color="red">{t('managers.inactive')}</Tag>
                      )}
                    </span>
                  )}
                </Flex>
              </Col>
              <Col span={6}>
                <Flex
                  vertical
                  gap={12}
                  align="center"
                  justify="center"
                  style={{ height: '100%' }}
                >
                  <Text>{t('managers.created_at')}</Text>
                  <Paragraph strong>
                    {dayjs(manager?.created_at).format('DD-MM-YYYY')}
                  </Paragraph>
                </Flex>
              </Col>
            </Row>
          </Col>

          <Col span={12}>
            <Form.Item
              name="first_name"
              label={t('managers.first_name')}
              rules={[{ required: true }]}
            >
              <Input
                disabled={!changeInfo}
                placeholder={t('managers.first_name_placeholder')}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              rules={[{ required: true }]}
              name="last_name"
              label={t('managers.last_name')}
            >
              <Input
                disabled={!changeInfo}
                placeholder={t('managers.last_name_placeholder')}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="email"
              label={t('managers.email')}
              rules={[{ type: 'email' }]}
              messageVariables={{
                label: t('validation.input_is_not_valid_email')
              }}
            >
              <Input
                disabled={!changeInfo}
                autoComplete="new-email"
                placeholder={t('managers.email_placeholder')}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="phone"
              label={t('managers.phone')}
              rules={[
                { required: true },
                {
                  // eslint-disable-next-line no-useless-escape
                  pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
                  message: t('validation.enter_phone_number')
                }
              ]}
            >
              <Input
                disabled={!changeInfo}
                placeholder={t('managers.phone_placeholder')}
              />
            </Form.Item>
          </Col>
          {manager?.plan && (
            <Col span={24}>
              <PlanItem
                start_date={manager.start_date}
                manager_id={manager.id}
                plan={manager.plan}
                total_price={manager.total_price}
                end_date={manager.expired_at}
              />
            </Col>
          )}
          <Col span={24}>
            <Typography.Text>{t('managers.plan_description')}</Typography.Text>
            <Flex flex={1} gap={12} style={{ marginTop: 24 }}>
              <Form.Item
                style={{ flex: 1 }}
                name="plan"
                rules={[
                  {
                    // eslint-disable-next-line no-useless-escape
                    pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
                    message: t('validation.only_numbers')
                  }
                ]}
              >
                <Input
                  style={{ width: '100%' }}
                  prefix={CURRENCY}
                  disabled={!changeInfo}
                  placeholder={t('managers.plan_price')}
                />
              </Form.Item>
              <Form.Item
                style={{ flex: 1 }}
                name="duration"
                rules={[
                  { required: isPlanFilled, message: t('validation.required') },
                  {
                    pattern: /^\d+$/,
                    message: t('validation.only_numbers')
                  }
                ]}
              >
                <InputNumber
                  disabled={!isPlanFilled || !changeInfo}
                  style={{ width: '100%' }}
                  min={1}
                  placeholder={t('managers.plan_duration')}
                />
              </Form.Item>
              <Form.Item
                style={{ flex: 1 }}
                name="start_date"
                rules={[{ required: isPlanFilled }]}
              >
                <DatePicker
                  disabled={!isPlanFilled || !changeInfo}
                  placeholder={t('managers.start_date')}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Flex>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default GeneralInfo;
