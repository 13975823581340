import { FC, ReactNode } from 'react';
import { Breadcrumb, Flex, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { BreadcrumbProps } from 'antd/lib';

import {
  Wrapper,
  StyledButton,
  StyledTitle,
  PageContainer,
  PageHeader
} from './styled';
import Loading from '../Loading';

interface IPageWrapper {
  title?: ReactNode;
  children?: React.ReactNode;
  breadcrumbs?: BreadcrumbProps;
  back?: boolean;
  extra?: ReactNode[];
  loading?: boolean;
  onClickBack?: () => void;
  color?: string;
  gap?: number;
}

const PageWrapper: FC<IPageWrapper> = ({
  title,
  children,
  breadcrumbs,
  back,
  color = 'layout-body-background',
  extra,
  loading,
  gap = 24,
  onClickBack
}) => {
  const navigate = useNavigate();

  return (
    <Wrapper $gap={gap} $color={color}>
      <PageHeader>
        <Flex gap={12}>
          {back && (
            <StyledButton
              type="link"
              icon={<ArrowLeftOutlined />}
              onClick={() => (onClickBack ? onClickBack() : navigate(-1))}
            />
          )}
          {breadcrumbs && <Breadcrumb {...breadcrumbs} />}
          {title &&
            (typeof title === 'string' ? (
              <StyledTitle level={2}>{title}</StyledTitle>
            ) : (
              <Flex>{title}</Flex>
            ))}
        </Flex>
        {extra && <Space>{extra.map(item => item)}</Space>}
      </PageHeader>
      <PageContainer>{loading ? <Loading /> : children}</PageContainer>
    </Wrapper>
  );
};

export default PageWrapper;
