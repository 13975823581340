import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

const getCustomers = (state: RootState) => state.customers;

export const selectCustomersData = createSelector(
  [getCustomers],
  customer => customer.customers
);

export const selectCustomersDataTotal = createSelector(
  [getCustomers],
  customer => customer.total
);

export const selectCustomersLoading = createSelector(
  [getCustomers],
  customer => customer.loading
);

export const selectCustomersLimit = createSelector(
  [getCustomers],
  customer => customer.limit
);

export const selectCustomersLoadingAction = createSelector(
  [getCustomers],
  customer => customer.loadingAction
);

export const selectCustomerDebtAnalytic = createSelector(
  [getCustomers],
  customer => customer.analytic
);

export const selectCustomerById = (id: string | number | null | undefined) =>
  createSelector([getCustomers], customer => (id ? customer.byId[id] : null));

export const selectCustomerByIdLoading = createSelector(
  [getCustomers],
  customer => customer.loadingById
);

export const selectOrderByCustomersData = createSelector(
  [getCustomers],
  customer => customer.orderByCustomer
);
