import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnsType } from 'antd/lib/table';
import { IEmployees } from 'app/features/employees/types';
import dayjs from 'dayjs';
import { App, Button, Flex, Table } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  EyeOutlined
} from '@ant-design/icons';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { getAllEmployees, removeEmployees } from 'app/features/employees/slice';
import {
  selectEmployeesData,
  selectEmployeesLimit,
  selectEmployeesLoading,
  selectEmployeesLoadingAction,
  selectEmployeesTotal
} from 'app/features/employees/selectors';
import { useSearchParams } from 'react-router-dom';

import { DetailsIcon } from './styled';
import EmployeesHeader from './components/EmployeesHeader';
import { _debounce } from 'utils/helpers';
import EmployeesModal from './components/EmployeesModal';

const debounce = _debounce();

const Employees = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { modal } = App.useApp();
  const [searchParams] = useSearchParams();
  const [page, setPage] = useState(0);
  const [modalState, setModalState] = useState({
    isOpen: false,
    employeeId: null as string | null
  });

  const loading = useAppSelector(selectEmployeesLoading);
  const loadingAction = useAppSelector(selectEmployeesLoadingAction);
  const employeesData = useAppSelector(selectEmployeesData);
  const limit = useAppSelector(selectEmployeesLimit);
  const total = useAppSelector(selectEmployeesTotal);

  useEffect(() => {
    const searchValue = searchParams.get('search');

    debounce(() => {
      dispatch(
        getAllEmployees({
          search: searchValue,
          page: page
        })
      );
    });
  }, [dispatch, page, searchParams]);

  const onChangePage = (page: number) => setPage(page);

  const closeModal = useCallback(() => {
    setModalState({ isOpen: false, employeeId: null });
  }, []);

  const openModal = useCallback((id: string | null) => {
    setModalState({ isOpen: true, employeeId: id });
  }, []);

  const deleteEmployees = useCallback(
    async (id: number) => {
      const searchValue = new URLSearchParams(window.location.search).get(
        'search'
      );

      dispatch(removeEmployees(id)).then(res => {
        if (res.meta.requestStatus === 'fulfilled') {
          dispatch(
            getAllEmployees({
              search: searchValue,
              page: page
            })
          );
          modal.success({ title: t('employees.employees_was_removed') });
        }
      });
    },
    [dispatch, modal, page, t]
  );

  const showDeleteConfirm = useCallback(
    (id: number) => {
      modal.confirm({
        title: t('common.warning'),
        content: t('employees.delete_employee_warning'),
        icon: <ExclamationCircleFilled />,
        okText: t('common.yes'),
        cancelText: t('common.no'),
        okType: 'danger',
        onOk() {
          deleteEmployees(id);
        },
        okButtonProps: {
          loading: loadingAction
        }
      });
    },
    [deleteEmployees, loadingAction, modal, t]
  );

  const columns = useMemo<ColumnsType<IEmployees>>(
    () => [
      {
        title: t('employees.name'),
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: t('employees.surname'),
        dataIndex: 'surname',
        key: 'surname'
      },
      {
        title: t('employees.created_at'),
        dataIndex: 'created_at',
        key: 'created_at',
        render: item => dayjs(item).format('DD-MM-YYYY')
      },
      {
        title: t('locations.action'),
        dataIndex: 'action',
        width: 100,
        key: 'Action',
        align: 'center',
        render: (_, row) => {
          return (
            <Flex justify="space-between" gap={16}>
              <Button
                danger
                size="large"
                icon={<DeleteOutlined style={{ fontSize: 18 }} />}
                onClick={() => showDeleteConfirm(row.id)}
              />
              <Button
                size="large"
                onClick={() => openModal(row.id.toString())}
                icon={<EditOutlined style={{ fontSize: 18 }} />}
              />
            </Flex>
          );
        }
      },
      {
        title: '',
        dataIndex: 'details',
        key: 'details',
        width: 73,
        render: (_, row) => {
          return (
            <DetailsIcon
              onClick={() => navigate(`/orders/warehouse?worker=${row.id}`)}
            >
              <EyeOutlined style={{ fontSize: 20 }} />
            </DetailsIcon>
          );
        }
      }
    ],
    [navigate, openModal, showDeleteConfirm, t]
  );

  return (
    <>
      <EmployeesHeader
        onChangePage={onChangePage}
        onOpenModal={() => openModal(null)}
      />
      <Table
        loading={loading}
        columns={columns}
        dataSource={employeesData}
        rowKey="id"
        bordered
        pagination={{
          showSizeChanger: false,
          current: page + 1,
          onChange: page => {
            onChangePage(page - 1);
          },
          total,
          pageSize: limit
        }}
      />
      <EmployeesModal
        isOpen={modalState.isOpen}
        id={modalState.employeeId}
        onClose={closeModal}
      />
    </>
  );
};

export default Employees;
