import {
  AppstoreAddOutlined,
  CarOutlined,
  HomeOutlined,
  IdcardOutlined,
  PieChartOutlined,
  ShoppingCartOutlined,
  TeamOutlined,
  UserSwitchOutlined
} from '@ant-design/icons';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Menu, Modal, Typography } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { selectAdminOrder } from 'app/features/adminOrders/selectors';
import { useState } from 'react';
import { setAdminOrder } from 'app/features/adminOrders/slice';

const { Text } = Typography;

const MenuSider = () => {
  const dispatch = useAppDispatch();
  const router = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const adminOrders = useAppSelector(selectAdminOrder);
  const [isWarningVisible, setIsWarningVisible] = useState(false);
  const [pendingPath, setPendingPath] = useState<string | null>(null);

  const menuSection = [
    {
      label: <Link to={'/'}>{t('common.dashboard')}</Link>,
      key: '/home',
      icon: <PieChartOutlined style={{ fontSize: 20 }} />
    },
    {
      label: <Link to={'/locations'}>{t('common.locations')}</Link>,
      key: '/locations',
      icon: <AppstoreAddOutlined style={{ fontSize: 20 }} />
    },
    {
      label: <Link to={'/products'}>{t('common.products')}</Link>,
      key: '/products',
      icon: <ShoppingCartOutlined style={{ fontSize: 20 }} />
    },
    {
      label: <Link to={'/customers'}>{t('common.customers')}</Link>,
      key: '/customers',
      icon: <TeamOutlined style={{ fontSize: 20 }} />
    },
    {
      label: <Link to={'/employees'}>{t('common.employees')}</Link>,
      key: '/employees',
      icon: <UserSwitchOutlined style={{ fontSize: 20 }} />
    },
    {
      label: t('common.orders'),
      key: '/orders',
      icon: <ShoppingCartOutlined style={{ fontSize: 20 }} />,
      children: [
        {
          key: '/orders/warehouse',
          label: (
            <Link to={'/orders/warehouse'}>{t('common.warehouse_orders')}</Link>
          ),
          icon: <HomeOutlined style={{ fontSize: 20 }} />
        },
        {
          key: '/orders/managers',
          label: (
            <Link to={'/orders/managers'}>{t('common.manager_orders')}</Link>
          ),
          icon: <CarOutlined style={{ fontSize: 20 }} />
        }
      ]
    },
    {
      label: <Link to={'/managers'}>{t('common.managers')}</Link>,
      key: '/managers',
      icon: <IdcardOutlined style={{ fontSize: 20 }} />
    }
    // {
    //   label: <Link to={'/plans'}>{t('common.plans')}</Link>,
    //   key: '/plans',
    //   icon: <IdcardOutlined style={{ fontSize: 20 }} />
    // }
  ];

  const getSelectedKeys = (pathname: string) => {
    const allMenuItems = menuSection.flatMap(item =>
      item.children ? [item, ...item.children] : [item]
    );

    const foundItem = allMenuItems.find(item => pathname.startsWith(item.key));

    return foundItem ? [foundItem.key] : [menuSection[0].key];
  };

  const handleMenuClick = e => {
    const targetPath = e.key as string;

    if (
      adminOrders.length > 0 &&
      router.pathname.startsWith('/create-order/')
    ) {
      setPendingPath(targetPath);
      setIsWarningVisible(true);
      history.back();
    } else {
      navigate(targetPath);
    }
  };

  const confirmNavigation = () => {
    if (pendingPath) {
      navigate(pendingPath);
      setPendingPath(null);
      dispatch(setAdminOrder([]));
    }

    setIsWarningVisible(false);
  };

  const cancelNavigation = () => {
    setPendingPath(null);
    setIsWarningVisible(false);
  };

  return (
    <>
      <Menu
        defaultSelectedKeys={getSelectedKeys(router.pathname)}
        selectedKeys={getSelectedKeys(router.pathname)}
        mode="inline"
        theme="dark"
        items={menuSection}
        onClick={handleMenuClick}
        onChange={e => e.stopPropagation()}
      />
      <Modal
        title={t('orders.leave_page')}
        open={isWarningVisible}
        onOk={confirmNavigation}
        onCancel={cancelNavigation}
        okText={t('orders.confirm_leaving')}
        cancelText={t('orders.cancel_leaving')}
      >
        <Text>{t('orders.leave_warning_message')}</Text>
      </Modal>
    </>
  );
};

export default MenuSider;
