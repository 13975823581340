import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Flex, Input, Select, Typography } from 'antd';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { getAllOrders, setPage } from 'app/features/warehouseOrders/slice';
import { getAllEmployees } from 'app/features/employees/slice';
import { selectEmployeesData } from 'app/features/employees/selectors';

import {
  DatePickerStyled,
  DatePickerWrapper,
  SearchContainer,
  StyledHeader
} from './styled';

const { Title } = Typography;
const { Search } = Input;

interface IProps {
  date: {
    startDate: null | string;
    endDate: null | string;
  };
  onChangeDate: (startDate: string | null, endDate: string | null) => void;
  setOrderId: (e: number) => void;
}

const OrdersHeader: FC<IProps> = ({ date, onChangeDate, setOrderId }) => {
  const { t } = useTranslation();
  const employeesData = useAppSelector(selectEmployeesData);

  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  const onSearch = e => {
    const searchValue = e.target.value;
    dispatch(setPage(0));

    if (searchValue) {
      searchParams.set('search', searchValue);
      setSearchParams(searchParams);
    } else {
      searchParams.delete('search');
      setSearchParams(searchParams);
    }
  };

  const onSelectWorker = (e: number) => {
    dispatch(setPage(0));

    if (e || e === 0) {
      searchParams.set('worker', e.toString());
      setSearchParams(searchParams);
    } else {
      searchParams.delete('worker');
      setSearchParams(searchParams);
    }
  };

  const handleStartDateChange = dateValue => {
    const formattedDate =
      dateValue && dateValue.isValid()
        ? dayjs(dateValue).format('YYYY-MM-DD')
        : null;

    onChangeDate(formattedDate, date.endDate);
  };

  const handleEndDateChange = dateValue => {
    const formattedDate =
      dateValue && dateValue.isValid()
        ? dayjs(dateValue).format('YYYY-MM-DD')
        : null;

    onChangeDate(date.startDate, formattedDate);
  };

  const onSubmitDate = () => {
    if (date.startDate || date.endDate) {
      const searchValue = searchParams.get('search');
      const worker = searchParams.get('worker');
      const startDate = date.startDate
        ? date.startDate
        : dayjs('2024-01-11').format('YYYY-MM-DD');

      const endDate = date.endDate
        ? date.endDate
        : dayjs().format('YYYY-MM-DD');

      dispatch(setPage(0));
      setOrderId(0);
      dispatch(
        getAllOrders({
          search: searchValue,
          worker: worker,
          startDate: startDate,
          endDate: endDate
        })
      );
    }
  };

  const onRemove = () => {
    if (date.startDate || date.endDate) {
      const searchValue = searchParams.get('search');
      const worker = searchParams.get('worker');
      const startDate = dayjs('2024-01-11').format('YYYY-MM-DD');
      const endDate = dayjs().format('YYYY-MM-DD');
      dispatch(setPage(0));
      setOrderId(0);
      dispatch(
        getAllOrders({
          search: searchValue,
          startDate: startDate,
          worker: worker,
          endDate: endDate
        })
      ).then(res => {
        if (res.meta.requestStatus === 'fulfilled') {
          onChangeDate(null, null);
        }
      });
    }
  };

  const disableStartDate = current => {
    return date.endDate && current && current.isAfter(dayjs(date.endDate));
  };

  const disableEndDate = current => {
    return date.startDate && current && current.isBefore(dayjs(date.startDate));
  };

  const options = [
    ...(employeesData?.map(item => ({
      value: item.id,
      label: `${item.name} ${item.surname}`
    })) || {}),
    {
      value: 0,
      label: t('orders.admin')
    }
  ];

  useEffect(() => {
    dispatch(
      getAllEmployees({
        page: 0,
        limit: 999
      })
    );
  }, [dispatch]);

  return (
    <StyledHeader>
      <Title style={{ whiteSpace: 'nowrap' }} level={2}>
        {t('common.orders')}
      </Title>
      <Flex gap={16} vertical style={{ width: '100%' }} align="flex-end">
        <SearchContainer>
          <div className="select-wrapper">
            <Select
              allowClear
              value={
                searchParams.get('worker') !== null
                  ? Number(searchParams.get('worker'))
                  : undefined
              }
              onChange={onSelectWorker}
              style={{ width: '100%' }}
              placeholder={t('orders.select_worker')}
              options={options}
            />
          </div>
          <Search
            value={searchParams.get('search') || undefined}
            placeholder={t('orders.orders_search_placeholder')}
            onChange={onSearch}
          />
        </SearchContainer>
        <DatePickerWrapper>
          <DatePickerStyled
            allowClear={false}
            value={date.startDate ? dayjs(date.startDate) : null}
            placeholder={t('orders.orders_start_date_placeholder')}
            onChange={handleStartDateChange}
            disabledDate={disableStartDate}
          />
          <DatePickerStyled
            allowClear={false}
            value={date.endDate ? dayjs(date.endDate) : null}
            placeholder={t('orders.orders_end_date_placeholder')}
            onChange={handleEndDateChange}
            disabledDate={disableEndDate}
          />
          <Button onClick={onSubmitDate}>{t('orders.applyFilter')}</Button>
          <Button onClick={onRemove} danger>
            {t('orders.clearFilter')}
          </Button>
        </DatePickerWrapper>
      </Flex>
    </StyledHeader>
  );
};

export default OrdersHeader;
