import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { ErrorType, showErrorMessage } from 'helpers/errors';
import { client } from 'api';

import { AdminOrderState, AdminOrderType, CreateOrderBody } from './types';

const initialState: AdminOrderState = {
  order: [],
  loading: false
};

export const createOrder = createAsyncThunk(
  'adminOrder/createOrder',
  async (body: CreateOrderBody[]) => {
    try {
      await client.post('products/order-product', { products: body });
    } catch (error) {
      showErrorMessage(error as AxiosError<ErrorType>);
      throw error;
    }
  }
);

const adminOrderSlice = createSlice({
  name: 'adminOrder',
  initialState,
  reducers: {
    setAdminOrder: (state, action: PayloadAction<AdminOrderType[]>) => {
      state.order = action.payload;
    },
    removeProductFromOrder: (
      state,
      action: PayloadAction<{ id: number; row_id: number }>
    ) => {
      const { id, row_id } = action.payload;
      state.order = state.order.filter(
        product => !(product.id === id && product.row_id === row_id)
      );
    },
    addProductToOrder: (state, action: PayloadAction<AdminOrderType>) => {
      const { id, row_id } = action.payload;
      const existingProduct = state.order.find(
        product => product.id === id && product.row_id === row_id
      );

      if (existingProduct) {
        existingProduct.count = action.payload.count;
        existingProduct.price = action.payload.price;
      } else {
        state.order.push(action.payload);
      }
    }
  },
  extraReducers(builder) {
    builder
      .addCase(createOrder.pending, state => {
        state.loading = true;
      })
      .addCase(createOrder.fulfilled, state => {
        state.loading = false;
        state.order = [];
      })
      .addCase(createOrder.rejected, state => {
        state.loading = false;
      });
  }
});

export const { setAdminOrder, addProductToOrder, removeProductFromOrder } =
  adminOrderSlice.actions;

export default adminOrderSlice.reducer;
